<template>
    <flickity ref="flickity" :options="flickityOptions" class="full-width-banner carousel" aria-label="Carousel Imagens Leia Saúde Amil One">
        <div v-for="noticia in featured.data" class="noticia-banner carousel-cell">
            <picture>
                <source v-if="noticia.attributes.banner_full_mobile.data" media="(max-width:599px)" :srcset="api_url + noticia.attributes.banner_full_mobile.data.attributes.url">
                <img v-if="noticia.attributes.banner_full.data" :src="api_url + noticia.attributes.banner_full.data.attributes.url" :alt="noticia.attributes.banner_full.data.attributes.alternativeText" class="bg-noticia-banner" />
            </picture>
            <div class="container">
                <div class="noticia__content">
                    <h3 class="noticia__content__title">
                        {{ noticia.attributes.title_materia ? noticia.attributes.title_materia : noticia.attributes.title }}
                    </h3>
                    <div class="noticia__content__description" v-html="noticia.attributes.resumo_materia ? noticia.attributes.resumo_materia : noticia.attributes.excerpt">
                    </div>
                    <a :href="'/noticias/' + noticia.attributes.slug" class="btn-banner" aria-label="Ver mais noticias">Continuar lendo</a>
                </div>
            </div>
        </div>
    </flickity>
    <article>
        <section class="information">
            <div class="container">
                <h2 class="noticias-title" aria-label="últimas Noticias">Últimas Publicações</h2>
                <div v-masonry transition-duration="0.3s" item-selector=".item" class="masonry-container list-noticias">
                    <div v-masonry-tile class="noticias-item item" v-for="noticia in noticias.data" :key="noticia.id">
                        <a :href="'/noticias/' + noticia.attributes.slug" class="noticia-main-link">
                            <figure class="image-noticias" :class="'image' + getRandNumber()">
                                <img v-if="noticia.attributes.featured_image && noticia.attributes.featured_image.data" :src="api_url + noticia.attributes.featured_image.data.attributes.url" alt="Imagem Leia Saúde">
                                <div v-if="noticia.attributes.categories.data.length > 0" class="main-category" :style="{ backgroundColor: noticia.attributes.categories.data.length > 0 ? noticia.attributes.categories.data[0].attributes.cor : '#d5086e' }">{{ noticia.attributes.categories.data.length > 0 ? noticia.attributes.categories.data[0].attributes.name : '' }}</div>
                            </figure>
                            <div class="noticias-description">
                                <h3 class="noticias_item_title">{{ noticia.attributes.title ? noticia.attributes.title : noticia.attributes.title_materia }}</h3>
                                <div class="excerpt-noticias" v-html="noticia.attributes.excerpt ? noticia.attributes.excerpt : noticia.attributes.resumo_materia"></div>
                            </div>
                            <div class="after">
                                <h3 class="noticias_item_title">{{ noticia.attributes.title ? noticia.attributes.title : noticia.attributes.title_materia }}</h3>
                                <div v-if="noticia.attributes.categories.data.length > 0" class="excerpt-noticias" v-html="noticia.attributes.categories.data[0].attributes.name"></div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </article>
</template>

<style lang="styl">
.flickity-page-dots {
    z-index: 499;
}
.full-width-banner {
        height: 460px;
        @media $mobile-max {
            height: 600px;
            .flickity-prev-next-button {
                width:25px;
                height:25px;
            }
            .flickity-button {
                background:transparent;
            }
        }
    }
    a.noticia-main-link  {
        color:inherit;
        position: relative;
        border-radius: 16px;
        background:#fff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
        display:block;
        .after {
            content: '';
            width:100%;
            min-height:0;
            position: absolute;
            background:#d5086e;
            color:#fff;
            z-index:100;
            border-radius:16px;
            text-align:center;
            padding:40px;
            display: flex;
            align-items: center;
            justify-content: center;
            justify-items:center;
            flex-wrap: wrap;
            opacity:0;
            bottom:0;
            .noticias_item_title {
                color:#fff;
                font-size: 22px
                width:100%;
            }
            .excerpt-noticias {
                width:100%;
            }
        }
        &:hover {
            .after {
                min-height:100%;
                opacity:1;
                transition: all 0.3s ease-in-out;
            }
        }
    }
    .noticias-title {
        color:#D5086E;
        padding:40px 0 0 0;
        margin-bottom:20px;
        border-bottom: 1px solid #D5086E;
        font-weight:300;
    }
    .noticia-banner {
        width:100%;
        position:relative;
        height:100%;
        z-index:0;
        overflow:hidden;
        &:before {
            content:'';
            display:block;
            position:absolute;
            height:100%;
            width:70%;
            z-index:1;
            background: linear-gradient(90deg,#fff 65%,hsla(0,0%,100%,0));
        }
        @media $mobile-max {
            position: relative;
            &:before {
                background: none;
            }
        }
    }
    .noticia__content__category {
        position:absolute;
        top:0;
        left:0;
        padding:5px;
    }
    .full-width-banner {
        .container {
            z-index:100;
            position:relative;
            height:100%;
            display:flex;
            align-items:center;
            @media $mobile-max {
                position: absolute;
                top: 0 ;
            }
        }
    }
    .information {
        margin-bottom: 50px;
    }
    .noticia__content {
        width:100%;
        padding:30px 15px;
        position:relative;
        color:#fff;
        .noticia__content__title {
            color:#D5086E;
            text-shadow: 0px 0px 10px #fff;
            font-size:45px;
            font-weight:500;
            max-width:50%;
            line-height:1.1em;
            margin-bottom:15px;
            @media $mobile-max {
                font-size:22px;
                max-width:100%;
                margin-top:16px;
            }
            @media $small-mobile-max {
                font-size:22px;
            }
        }
        @media $mobile-max {
            position:absolute;
            top:0;
            padding:0;
            width:calc(100% - 90px);
            left:50%;
            transform:translateX(-50%);
            height:100%;
            display:flex;
            flex-direction:column;
            padding-top:10px;
        }
    }
    .bg-noticia-banner {
        height:auto;
        min-height:100%;
        min-width:70%;
        position:absolute;
        right:0;
        max-width:none;
        @media $mobile-max {
            width: 100%;
        }
    }

    .image-noticias {
        position: relative;
        border-radius: 16px 16px 0 0;
        .main-category {
            position: absolute;
            top: 0;
            left: 0;
            background: #D5086E;
            color: #fff;
            padding: 5px 10px;
            font-size: 12px;
            font-weight: 500;
        }
        width: 100%;
        height: 300px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &.image0 {
            height:220px;
        }
        &.image1 {
            height:300px;
        }
        &.image2 {
            height:450px;
        }
        &.image3 {
            height:180px;
        }
        &.image4 {
            height:200px;
        }
    }
    .list-noticias {
        margin:0 -10px;
    }
    .noticias-description {
        padding: 28px;
    }
    .noticias-item {
        max-width:33%;
        margin:0;
        background:transparent;
        padding:10px;
        @media $large-mobile {
            max-width: 50%;
            padding: 20px;
        }
        @media $mobile-max {
            max-width: 100%;
            padding: 20px;
        }
    }
    .noticias_item_title {
        color:#d5086e;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        margin-top:20px;
    }
    .excerpt-noticias {
        padding:10px 0;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
    }
    .noticia__content__description {
        max-width:50%;
        color:#3B3B3A;
        font-size:28px;
        font-weight:400;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        @media $mobile-max {
            font-size:22px;
            max-width:100%;
            max-height: 200px !important;
        }
        @media $small-mobile-max {
            font-size:15px;
        }
    }
    .btn-banner {
        background:#D5086E;
        color:#fff;
        padding:10px 20px;
        border-radius:20px;
        font-size:16px;
        font-weight:300;
        text-transform:uppercase;
        margin-top:20px;
        display:inline-block;
        font-weight: 500;
        &:hover {
            color:#fff;
            background: #F60D81;
        }
        @media $mobile-max {
            font-size:12px;
            padding:10px 15px;
            align-self:flex-start;
        }
    }
</style>

<script>
import Flickity from 'vue-flickity';
import { useRoute } from "vue-router";
import axios from "axios";
export default {
    name: "NoticiasList",
    props: {
        noticias: Object,
        featured: Object
    },
    components: {
        Flickity
    },
    data() {
        return {
            api_url: process.env.VUE_APP_STRAPI_URL,
            banners: {},
            flickityOptions: {
                initialIndex: 0,
                prevNextButtons: true,
                pageDots: true,
                wrapAround: false,
                fullscreen: true,
                setGallerySize: false
            }
        }
    },
    mounted() {
        /* on resize window update flickty*/
        window.addEventListener('resize', () => {
            this.$refs.flickity.resize();
        });
    },

    methods: {
        getRandNumber() {
            //return a random number between 0 and 4
            return Math.floor(Math.random() * 4);

        },
        next() {
            this.$refs.flickity.next();
        },

        previous() {
            this.$refs.flickity.previous();
        },

    },
}
</script>
