<template> 
    <div class="video_content" ref="container" v-html="container_html" @click="loadIframe()" :style="{backgroundImage: 'url(https://i3.ytimg.com/vi/'+getVideoId(url)+'/maxresdefault.jpg)', maxWidth: width+'px', width: width+'px', height: height+'px'}"></div>      
</template>

<style lang="styl">
.video_content {
    display: flex;
    justify-content: center;
    margin: auto !important;  
    border-radius: 23px;
    border: 1px solid #D41270 !important;
    background-color: #000;
    background-size: cover;
    cursor: pointer;
    @media $large-mobile {
        width: 100% !important;
        height: 45vw !important;
    }
    &.clicked {
        border: 0 !important;
        background-image: none !important;
        width: auto !important;
        height: auto !important;
    }
    iframe {
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #D41270;
        border-radius: 23px;
        @media $large-mobile {
            max-width: 100%;
            height: 299px !important;
        }
    }
    p {
        color:#505050;
        font-size: 18px;
        line-height: 1.5;
    }
}
</style>
<script>
export default {
    name: "ComponentVideo",
    props: {
        title: String,
        url: String,
        width: Number,
        height: Number,
    },
    mounted() {
        this.resizeVideo();
        window.addEventListener('resize', this.resizeVideo);
        const youtubeIframeApi = document.createElement('script');
        youtubeIframeApi.setAttribute('src', 'https://www.youtube.com/iframe_api');
        document.head.appendChild(youtubeIframeApi);
    },
    
    data() {        
        return {
            api_url: process.env.VUE_APP_STRAPI_URL,
            container_html: '<svg height="48" version="1.1" viewBox="0 0 68 48" width="68"><path class="ytp-large-play-button-bg" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg>',
        };
             
    },
    methods: {        
        resizeVideo() {
            const video = this.$refs.video; 
            if(video) {
                const videoWidth = video.offsetWidth;
                const videoHeight = videoWidth * 0.6;
                video.style.height = videoHeight + 'px';
            }
        },
        getVideoId(url) {
            return url.match(/(?<=embed\/)[0-9a-zA-Z_-]{11}/)[0];
        },
        loadIframe() {
            this.$refs.container.classList.add('clicked');
            this.container_html = `<iframe quality="hd360" width="${this.width}" height="${this.height}" src="${this.url}?autoplay=1" title="${this.title}" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay" allowfullscreen></iframe>`;
        }
    }
}
</script>
