<template>
    <div>            
        <component v-for="component in componentList" :key="component.__typename" :is="component.__typename" :component="component" :pageId="pageId"> {{component.__typename}}</component>  
    </div>
</template>
<script>
import {defineAsyncComponent,defineComponent} from "vue";

const requireContext = require.context(
    "@/components/dynamics", //path to components folder which are resolved automatically
    true,
    /\.vue$/i,
    "sync"
);
let componentNames= requireContext
    .keys()
    .map((file) => file.replace(/(^.\/)|(\.vue$)/g, ""));

let components=[];

componentNames.forEach((component) => { //component represents the component name
    components[component] = defineAsyncComponent(() => //import each component dynamically
        import("@/components/dynamics/" + component + ".vue")
    );
});


export default defineComponent({
    name: "ComponentList",
    props: {
        components: Object,
        pageId: Number
    },
    components,
    methods: {
        componentListUpdate(components) {            
            this.componentList = components;
            //this.$forceUpdate();
        },
    },
  
    setup(props) {        
        const componentList = [];                    
        try {
            props.components.forEach(component => {                        
                componentList.push({
                        __typename: 'Component'+component.__component.replace(/-.|\../g, x=>x[1].toUpperCase()).replace(/^./g, x=>x[0].toUpperCase()),
                        ... component
                });                        
            });
        } catch (error) {
            console.log(error);
        }        
        return {  
            componentList: componentList
        };
    },
});
</script>
