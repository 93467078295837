<template>
    <section v-if="component" class="container">
        <div class="credenciados">
            <h2 v-if="component.title" @click="activeCredenciados">{{ component.title }}</h2> 
            <article class="dn">
                <p class="description" v-html="component.description"></p>
                <div class="credenciado-scoll">
                    <div class="list-credenciados" v-for="rede_credenciada in rede" :key="rede_credenciada.id">
                     <div v-if="currentDate >= rede_credenciada.attributes.data_inicio || currentDate <= rede_credenciada.attributes.data_fim">
                        <h3 v-if="rede_credenciada.attributes.link_credenciado">
                        <strong><a :href="rede_credenciada.attributes.link_credenciado" class="link_credenciado" target="_blank">{{ rede_credenciada.attributes.name }}</a></strong>
                        </h3> 
                        <h3 v-else>
                            <strong>{{ rede_credenciada.attributes.name }}</strong>
                        </h3>
                        <p class="credenciado-especialidade">Especialidade:  {{ rede_credenciada.attributes.especialidade }}</p>
                        <div class="credenciado" v-for="rede_item in rede_credenciada.attributes.cidade" :key="rede_item.id">
                        <p class="credenciado-endereco">{{ rede_credenciada.attributes.endereco }}, {{ rede_item.attributes.name }}, {{ rede_item.attributes.state }}</p>
                            <a                             
                            class="credenciado-celular"  
                            :href="rede_credenciada.attributes.whatsapp ?`https://api.whatsapp.com/send?phone=55${rede_credenciada.attributes.contato}` : `tel:${rede_credenciada.attributes.contato}`"
                            :target="rede_credenciada.attributes.whatsapp ? '_blank' : ''">
                                {{ formatCelNumber(rede_credenciada.attributes.contato) }}
                            </a>
                            <p class="credenciado_legenda" v-html="rede_credenciada.attributes.legenda"></p>
                        </div>
                     </div> 
                </div>
                </div>
            </article>
        </div>        
    </section>
</template>
<script>

import axios from 'axios';
import _ from 'lodash';
export default {
    name: "ComponentSectionsNovosCredenciados",
    props: {
        component: Object,
    },
    
    data() {        
        return {
            rede: {},
            api_url: process.env.VUE_APP_STRAPI_URL,
            currentDate: null,
            arrayRede: {},
            credenciado_filter: {}
        };
    },
    methods: {
        activeCredenciados: function () {
            document.querySelector('.credenciados article').classList.toggle('dn');
            document.querySelector('.credenciados').classList.toggle('active');
        },
        strapi_api_url: function () {
            return process.env.VUE_APP_STRAPI_API_URL;
        },
        formatCelNumber(number) {
            const formattedNumber = number.replace(/(\d{2})(\d{4,5})(\d{4})/, "($1) $2-$3");
            return formattedNumber;
        },
        getCidades: async function () {
            const api_url = this.strapi_api_url();
            let filters = `/credenciados/?&filters[data_inicio][$lte][0]=${this.currentDate}&sort[0]=peso:desc&sort[1]=updatedAt:desc&pagination[page]=1&pagination[pageSize]=9999&populate=*`;
            let cidades = await axios.get(`${api_url}${filters}`).then(response => {
                return response.data.data;
            });
            const res = cidades;
            
            return res;
        },

        getCurrentDate: function() {
            const d = new Date();
            const ye = new Intl.DateTimeFormat('pt-br', { year: 'numeric' }).format(d);
            const mo = new Intl.DateTimeFormat('pt-br', { month: '2-digit' }).format(d);
            const da = new Intl.DateTimeFormat('pt-br', { day: '2-digit' }).format(d);
            this.currentDate = `${ye}-${mo}-${da}`;
        },
        async setup() {
            const cidades = await this.getCidades();
            const rede = [];
            var promises = [];
            cidades.forEach(element => {
                rede.push(element);
            });
            return Promise.all(promises).then(() => {
                return {
                    cidades,
                    rede
                };                
            });                    
        },
    },
    async mounted() {
        const data = await this.setup();
        this.rede = data.rede;

        this.getCidades();        

        const link = document.querySelector(".description p a");
        if (link) link.setAttribute("target", '_blank');
        
    },
    created() {        
        this.getCurrentDate();
    }
}
</script>
<style lang="styl" scoped>


.dn {
    display: none;
}
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  .credenciado-scoll {
    scrollbar-width: auto;
    scrollbar-color: #929292 #eeeeee;
  }


  .credenciado-scoll::-webkit-scrollbar {
    width: 10px;
    height: 20px;
  }

  .credenciado-scoll::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  .credenciado-scoll::-webkit-scrollbar-thumb {
    background-color: #929292;
    border-radius: 10px;
    border: 0px solid #ffffff;
  }
.container {
    @media $mobile-max {
        padding: 10px; 
    }
}
.credenciados {
    border-radius: 24px;
    padding-bottom: 30px;
    color: #000;
    margin-bottom: 50px !important;
    background: none;
    transition: .2s;
    height: 100px;
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    &.active {
        height: 800px !important;
        background: #FFF !important;
        h2 {
            &::after {
                transform: rotate(180deg);
                top: -28px;
                transition: .4s
            }
        }
    }
    h2 {
        background: #d5086e;
        border-radius: 24px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #F6F6F6;
        font-size: 23px;
        cursor: pointer;
        @media $mobile-max {
            font-size: 18px;
        }
        &::after {
            background: url(../../images/arrow-white.svg);
            background-repeat: no-repeat;
            background-position:center;
            content:"";
            display: flex;
            height: 50px;
            align-items: center;                
            width: 50px;
            transition: .4s;
            cursor: pointer;
            background-size: 28px;
            @media $mobile-max {
                height: 40px;
                width: 34px;
                background-size: 19px;
            }
        }
        
    }

    .credenciado-scoll {
        height: 600px;
        overflow: hidden;
        position: relative;
        overflow-y: auto;
    }
    .list-credenciados {
        padding: 0 64px;
        margin-top: 30px;
        transition: .4s;
        @media $mobile-max {
            padding: 0 30px;
        }
        h3 {
            font-size: 17px;
            margin-bottom: 15px;
        }
        .link_credenciado {
            &:hover {
                color: #595656;
            }
        }
        p,
        .credenciado-celular {
            font-size: 17px;
        }
        .credenciado {            
            line-height: 1.5;
            border-bottom: 2px solid #d3d2d2;
            display: flex;
            flex-direction: column;
            .credenciado_legenda {                
                margin-bottom: 9px;
                overflow:hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 500;
                -webkit-box-orient: vertical;
            }
        }
        &.dn {
            display: none;
        }
       
            
            
            .credenciado-endereco, {
                margin-bottom: 0 !important;
                margin-top: 0 !important;
            }

            .credenciado_legenda {
                margin-bottom: 15px;
                margin-top: 0 !important;
            }

            .credenciado-especialidade,
            
            .credenciado-celular {
                margin-bottom: 15px !important;
                margin-top: 0 !important;
            }
    }
    .description {
        background: #EFEFEF;
        border-radius: 15px;      
        padding: 20px;        
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px !important;
        margin: 30px auto 0;
        color: #707070;
        margin-bottom: 0 !important;
        width: 90%;        
        font-weight: normal !important;
        
        @media $mobile-max {
            text-align: center;
            font-size: 16px !important;
        }
        p {
            margin: 0 !important;
        }
        a {
            cursor: pointer;
            span {
                font-weight: normal !important;
            }
        }
    }
}
</style>
