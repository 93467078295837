<template>
    <div>
      <div v-if="component" class="cards_with_text">
        <div class="container">
          <h2 v-if="component.title">{{ component.title }}</h2>    
            <div class="cards_with_text__list"            
                ref="slider__wrapper">
                <div class="card-list__slider"
                    ref="slider">
                      <swiper
                          :breakpoints="swiperOptions.breakpoints" class="swiper-teste" ref="swiper" >
                          <swiper-slide v-for="card in component.cards" :key="card.id" ref="swiperSlide">                         
                              <Card :component="card" />                        
                          </swiper-slide>
                      </swiper>
                </div>
            </div>        
            <div class="navigation-swiper" :class="{show : showArrows}">
              <button @click="prevSwiper('.swiper-teste')" class="prev-swiper-nav">Prev</button>
              <button @click="nextSwiper('.swiper-teste')" class="next-swiper-nav">Next</button>                
            </div>
            <div class="cards_with_text__link" v-if="component.url_link">                      
              <a v-if="component.link_text" :href="component.url_link" class="button button--primary">
                  {{component.link_text }}
              </a>
            </div>  
        </div>
      </div>
    </div>
  </template>
  <style lang="styl">
  .cards_with_text {
      background:  #F6F6F6;
    padding-bottom: 80px;
    .container {
      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        text-align: center;
        color: #D5086E;
        margin-bottom: 30px;
      }
    }
    .cards_with_text__link {
        font-family: 'Amil Typeface';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        
        line-height: 19px; 
        text-align: center;      
        a {
            color:#D5086E;
        }  
        &:after {
            content: '';
            display: block;
            width: 150px;
            max-width:100%;
            height: 4px;
            background: #D5086E;
            margin: 10px auto;            
        }
        @media $mobile-max {
          font-size: 16px;
        }
    }
    .cards_with_text__list {
          /*overflow: hidden;*/
          max-width: 100%;
          margin-bottom: 30px;
          padding: 40px 0 0 0;
          @media (max-width: 1024px) {
              padding: 0;
          }
      }
      .card-list__slider {
          display:flex;
          width: 100%;
          @media $mobile-max {
              margin: 0px 0;
          }
          .card {
              width: 246px;
              background: #FFF;
              height: 326px;
              display: flex;
              align-items: flex-start;
              box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
              padding: 20px;
              @media $mobile-max {
                  width: 146px !important;
                  height: 230px !important;
              }
          }
          .swiper {
              padding: 0 80px 0 33px !important;
              @media $mobile-max {
                  padding: 20px !important;
              }
          }
          .swiper-slide {
              width: 280px !important;
              display: flex !important;
              @media $mobile-max {
                  width: 154px !important;
              }
          }
                  
          .swiper-wrapper {
              gap: 3px !important;
              @media $mobile-max {
                  padding: 0;
              }
          }     
      }
      .prev-swiper-nav {
          background: url(../../images/prev.svg);
          background-repeat: no-repeat;            
          width:30px;
          height:30px;
          border:0;
          text-indent:-9999px;
          margin:0 10px;
      }
  
      .next-swiper-nav {
          background: url(../../images/next.svg);
          background-repeat: no-repeat;                                
          border:0;
          width:30px;
          height:30px;
          text-indent:-9999px;
          margin:0 10px;
                  
      }
      .navigation-swiper {
          width:100%;
          text-align:center;
          margin-bottom:50px;
          display:none;
          &.show{
              display:block;
          }
      }
  }
  
  
  
  </style>
  <script>
  
  import Card from "../Card.vue";
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/css';
  export default {
      name: "ComponentSectionsCards_with_text",
      props: {
          component: Object,
      },
      components: {
          Card, 
          Swiper,
          SwiperSlide
      },
      methods: {
          nextSwiper(swiper) {
              const swiperEl = document.querySelector(swiper);
              swiperEl.swiper.slideNext();            
          },
          prevSwiper(swiper) {
              const swiperEl = document.querySelector(swiper);
              swiperEl.swiper.slidePrev();            
          },
          checkArrows() {
              const swiperEl = this.$refs.swiper;
              const swiperSlide = this.$refs.swiperSlide;
              // get swiper El width
              const swiperElWidth = swiperEl.$el.clientWidth;
              // get swiper slide width
              const swiperSlideWidth = swiperSlide[0].$el.clientWidth;            
              const totalWidth = this.component.cards.length * swiperSlideWidth;
              if(totalWidth > swiperElWidth) {
                  this.showArrows = true;
              } else {
                  this.showArrows = false;
              }
          }
      },
      data() {
            return {
                api_url: process.env.VUE_APP_STRAPI_URL,
                showArrows: false,
                swiperOptions: {
                    breakpoints: {       
                        320: {       
                            slidesPerView: 2,
                            spaceBetween: 0,  
                        },
                        699: {       
                            slidesPerView: 2,       
                            spaceBetween: 0,   
                        },
                        990: {       
                            slidesPerView: 3,       
                            spaceBetween: 0 ,    
                        },
                        1100: {
                            slidesPerView: 3,
                            spaceBetween: 0,
                        },
                        1194: {
                            slidesPerView: 4,
                            spaceBetween: 0,
                        },
                    },        
                }
            };
      },  
     mounted() {          
          this.checkArrows();
          window.addEventListener('resize', this.checkArrows);
     },    
  };
</script>
 