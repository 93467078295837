<template>
    <div class="background-featured">
        <div v-if="component" class="featured">
            <div class="container">
                <h2 class="featured__title">{{component.title}}</h2>
                <div class="featured__text" v-html="component.description"></div>                
                <div class="circle-list"            
                    ref="slider__wrapper">
                    <div class="circle__slider"
                        ref="slider">
                        <swiper
                        :breakpoints="swiperOptions.breakpoints" class="swipper-featured"  ref="swiper" @slideChange="onSlideChange">
                            <swiper-slide  v-for="component in component.circles" :key="component.id"   ref="swiperSlide"> 
                                <div>                                    
                                    <Circle                        
                                    :component="component" />
                                </div>                                
                            </swiper-slide>
                        </swiper>                                          
                    </div>
                    <div class="navigation-swiper" :class="{show : showArrows}">
                        <button @click="prevSwiper('.swipper-featured')" class="prev-swiper-nav" :disabled="disabledPrev">Prev</button>
                        <button @click="nextSwiper('.swipper-featured')" class="next-swiper-nav" :disabled="disabledNext">Next</button>                                 
                    </div>     
                </div>                
                    <a class="featured_link" :href="component.button_link">
                        {{ component.button_text }}
                    </a>  
                </div>               
        </div>
    </div>
</template>
<style lang="styl">
.background-featured {
    background: #f6f6f6;
}
.featured {
    text-align:center;
    background:#3B3B3A;
    color:#fff; 
    padding: 100px 0 100px 0; 
    position:relative; 
    @media $mobile-max { 
        padding: 150px 20px 150px 20px;
    }
    .prev-swiper-nav {
        background: url(../../images/prev-white.svg);
        background-repeat: no-repeat;            
        width:30px;
        height:30px;
        border:0;
        text-indent:-9999px;
        margin:0 10px;
        &:disabled {
            opacity: 0.5;
        }
    }

    .next-swiper-nav {
        background: url(../../images/next-white.svg);
        background-repeat: no-repeat;                                
        border:0;
        width:30px;
        height:30px;
        text-indent:-9999px;
        margin:0 10px;
        &:disabled {
            opacity: 0.5;
        }
                
    }
    .navigation-swiper {
        width:100%;
        text-align:center;
        margin-bottom:50px;
        display:none;
        &.show {
            display:block;
        }
        @media (max-width:1262px){
            
            margin-top: 30px;
        }
    }
}
.featured__title {
    color:#FFF;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    @media $mobile-max { 
        font-size:28px;
        line-height:29.15px;
    }
}
.featured__text {
    font-weight: 400;
    font-size: 22px;
    line-height: 38px;
    margin-top:40px;
    margin-bottom: 34px;
    @media $mobile-max { 
        font-size:18px;
        line-height:32px;
    }
}
.circle-list {  
    margin-bottom: 84px;
  @media $mobile-max {
    padding: 0;
  }
}

.circle__slider {
    display:flex;
    justify-content:center;
    gap: 40px;
    margin:40px;
    @media $mobile-max {
        margin: auto;
        gap: 40px;
    }

    .swiper-button-prev::before {
        background: url(../../images/prev.svg);
        background-repeat: no-repeat;
        content:"";
        display: inline-block;
        height: 50px;
        position: absolute;
        bottom: -16px;
        width: 32px;
        left: -1px;
    }

    .swiper-button-next::before {
        background: url(../../images/next.svg);
        background-repeat: no-repeat;
        content:"";
        display: inline-block;
        height: 32px;
        position: absolute;
        bottom: 4px;
        width: 50px;    
        right: -19px;
    }
    .swiper-button-next, .swiper-button-prev {
        color: #D5086E;
        display: block;
        &::after {
            display: none;
        }
    } 
   
       
} 
.featured_link {
    width: 100%;
    height: 50px;
    margin-top: 80px;
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
    @media $large-mobile {
        display: none;
    }
    &::after {
        content: '';
          display: block;
          width: 220px;
          max-width:100%;
          height: 4px;
          background: #D5086E;
          margin: 10px auto; 
    }
    &:hover {
        color: #FFF;
    }
}
.circle {
    @media $large-mobile {
       width: 250px;
    }
    .circle__title {
        @large-mobile {
            marign-top: 80px;
        }
    }
}
   
</style>
<script>
import Circle from '../Circle.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);
import 'swiper/css/navigation'
import 'swiper/css';
export default {
    name: "ComponentSectionsCards",
    props: {
        component: Object,
    },
    components: {
        Circle,
        Swiper,
        SwiperSlide
    },
    data() {
        const maxSlides = 4;
        const maxSlidesPerView = this.component.circles.length > maxSlides ? maxSlides : this.component.circles.length;
        const maxSlidesPerViewMedium = this.component.circles.length > 3 ? 3 : this.component.circles.length;
        return {
            api_url: process.env.VUE_APP_STRAPI_URL,
            showArrows: false,
            disabledPrev: true,
            disabledNext: false,
            dist: {
              finalPosition: 0,
              startX: 0,
              movement: 0
            },
            swiperOptions: {
                navigation: true,
                breakpoints: {       
                    320: {       
                        slidesPerView: 1,
                        spaceBetween: 0     
                    },
                    699: {       
                        slidesPerView: 2,       
                        spaceBetween: 0     
                    },
                    990: {       
                        slidesPerView: maxSlidesPerViewMedium,       
                        spaceBetween: 30     
                    },
                    1100: {
                        slidesPerView: maxSlidesPerView,
                        spaceBetween: 20
                    }
                },
        
            }
        };
    },
    methods: {
        nextSwiper(swiper) {
            const swiperEl = document.querySelector(swiper);
            swiperEl.swiper.slideNext();            
        },
        prevSwiper(swiper) {
            const swiperEl = document.querySelector(swiper);
            swiperEl.swiper.slidePrev();            
        },
        checkArrows() {
            const swiperEl = this.$refs.swiper;
            const swiperSlide = this.$refs.swiperSlide;
            // get swiper El width
            const swiperElWidth = swiperEl.$el.clientWidth;
            // get swiper slide width
            const swiperSlideWidth = swiperSlide[0].$el.clientWidth;            
            const totalWidth = this.component.circles.length * swiperSlideWidth;
            if(totalWidth > swiperElWidth) {
                this.showArrows = true;
            } else {
                this.showArrows = false;
            }
        },
        onSlideChange(swiper) {
            
            if(swiper.isBeginning) {
                this.disabledPrev = true;
            } else {
                this.disabledPrev = false;
            }
            if(swiper.isEnd) {
                this.disabledNext = true;
            } else {
                this.disabledNext = false;
            }
            
            // get swiper El width
        }           
    },
    mounted() {
        this.checkArrows();
        window.addEventListener('resize', this.checkArrows);                
    },
};
</script>
