<template>
  <div>
    <div v-if="component">
        <section class="imgWithList">
          <div class="center-content">
            <div class="flexed keep-flex aic-center jfc-between" :class="{ row_reverse : component.to_right }">
              <img
                class="desk-version"
                :src="api_url + component.image.data.attributes.url"
              />
              <div class="title-section">
                <h2 class="t-45 t-lh-56 t-darkBlue t-sb mb-2">
                  {{ component.title }}
                </h2>
                <img
                  class="mobile-version"
                  :src="api_url + component.image.data.attributes.url"
                />
                <div class="t-18 t-lh-27">
                  {{ component.text }}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  </div>
</template>
<style lang="styl">
.imgWithList{
    padding: 50px 0px 50px;
    .flexed{
        img{
            @media $large-mobile{
                margin: 0px auto 45px;
                max-width: 390px;
                position: initial;
            }

            @media $mobile-max{
                max-width: 100%;
            }
        }

        @media $large-mobile{
            flex-direction: column !important;
        }
    }

    .title-section{
        margin-left: auto;
        max-width: 346px;

        p{
            max-width: 363px;

               @media $large-mobile{
                    max-width: 500px;
                    text-align: center;
                }
        }

        ul{
            li:before{
                background-color: $blue;
                border-radius: 50%;
                content:"";
                display: inline-flex;
                height: 11px;
                margin-right: 7px;
                width 11px;
            }

            @media $large-mobile{
                text-align: left;
                margin:auto;
                max-width: 145px;
            }
        }

        @media $large-mobile{
            margin: 30px auto 0px;
            max-width: 500px;
            text-align: center;

            h2{
                margin-bottom: 45px;
            }
        }
    }

    .title-section:nth-child(2){
        margin-right: auto;
    }

    .desk-version{
        @media $mobile-max{
            display: none;
        }
    }

    .mobile-version{
        display: none;

        @media $mobile-max{
            display: block
        }
    }

    @media $large-mobile{
        padding: 36px 0px;
    }

     @media $mobile-max{
        .t-45{
            font-size: 30px;
        }

        .t-lh-56{
            line-height: 40px;
        }
    }
}
</style>
<script>
export default {
  name: "ComponentSectionsImgWithList",
  props: {
    component: Object,
  },
  data() {
    return {
      api_url: process.env.VUE_APP_STRAPI_URL,
    };
  },
};
</script>
