<template v-if="busca">   
  <Header />
    <main class="structure  search main-page">
        <section class="information">
            <div class="container">                        
                <h2 class="noticias-title">Resultado da Busca</h2>
                <div v-if="busca.length > 0" v-for="resultado in busca" class="resultado-item">
                    <figure class="image-busca-item" v-if="resultado.image">
                        <img :src="strapi_url + resultado.image.url" :alt="resultado.image.alternativeText ">                
                    </figure>
                    <div class="image-placeholder" v-else>                        
                    </div>
                    <div class="text-resultado">
                        <h2 v-if="resultado.title"> {{resultado.title}}</h2>
                        <div class="resultados-description"  v-html="resultado.description"></div>
                        <a :href="`${resultado.slug}`" class="btn btn-primary">Ver mais</a>
                    </div>
                </div>
                <div v-else>
                    <div class="text-resultado">
                        <h3>A busca não retornou nenhum resultado.</h3>    
                    </div>
                </div>  
            </div>
        </section>
    </main>
  <Footer />                  
</template>
<style lang="styl">
.search {
    .information {
        @media $mobile-max {
            padding: 0 16px;
        }
        
    }
    .image-placeholder {
        background:#fff;
        width:30%;
        height:200px;
        @media $mobile-max {            
            height:100px;
        }
    }
}
.btn-primary {
    background-color: #D5086E;
    border-color: #D5086E;
    color: #fff;
    padding:10px 40px;
    display:inline-block;
    border-radius: 22px;
    margin-top:40px;
    &:hover {
        background-color: #D5086E;
        border-color: #D5086E;
        color: #fff;
    }
    @media $mobile-max {
        padding:10px 20px;
        font-size:14px;
    }
}
.resultado-item {
    display:flex;
    margin-bottom:40px;
    .image-busca-item {
        width:30%;
        img {
            width:100%;
        }
    }
    .text-resultado {
        width:70%;
        padding-left:20px;
        color:#D5086E;
        h2 {
            font-size:28px;
            font-weight:400;
            margin-bottom:20px;
        }
        .resultados-description {
            font-size:18px;
            font-weight:400;
            line-height:1.5;
        }
        @media $mobile-max {
            h2 {
                font-size:16px;
            }
            .resultados-description {
                font-size:14px;
            }
        }
    }
}
</style>
<script> 
import axios from "axios";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import NoticiasList from "../components/NoticiasList.vue";
import titleMixin from "../mixins/mixinTitle";
import { useRouter } from "vue-router";
export default {
name: 'ListBusca',
title () {
    return `${this.pageTitle}`
},
metaTags() {    
    return this.metaTagsTags;
},  
mixins: [
  titleMixin 
],  
components: {    
    Header,
    Footer,
    NoticiasList
},
async setup() {    
    const router = useRouter();
    let busca = [];
    const queries = router.currentRoute.value.query;    
    const api_url = process.env.VUE_APP_STRAPI_URL;
    const strapi_url = process.env.VUE_APP_STRAPI_URL; 
    const level = localStorage.getItem('level');
    if(queries.q) {
        const res = await axios.get(api_url+`/search/?q=${queries.q}&level=${level}`);
        const noticias = res.data;        
       busca = noticias.results;
    }        
    const pageTitle = `Busca por ${queries.q}` ;              
    return {
        strapi_url,
      api_url,      
      busca,
      pageTitle
    }
   }
   
};
</script>
