import { createApp, h } from "vue";
import { createRouter, createWebHistory } from "vue-router";

import Page from "./containers/Page";
import Noticia from "./containers/Noticia";
import Noticias from "./containers/Noticias";
import Busca from "./containers/Busca";
import App from "./App.vue";
import VueTheMask from 'vue-the-mask';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { createStore } from 'vuex';

import gtmTagsMixin from './mixins/mixinTagsGTM';
import Cookies from './mixins/mixinCookie';
import { VueMasonryPlugin } from "vue-masonry";

function lazyLoad(view){
  return() => import(`./containers/${view}.vue`)
}

const routes = [      
    { path: "/", component: lazyLoad('Page'), props: { slug: "home" } },
    { path: "/:slug", component: lazyLoad('Page') },    
    { path: "/atendimento-exclusivo/:slug", component: lazyLoad('Page') },
    { path: "/diferenciais/:slug", component: lazyLoad('Page') },
    { path: "/noticias/:slug", component: lazyLoad('Noticia') },
    { path: "/noticias", component: lazyLoad('Noticias') },
    { path: "/leia-saude", component: lazyLoad('Noticias')},
    { path: "/noticias/categoria/:slug", component: lazyLoad('Noticias') },
    { path: "/busca", component: lazyLoad('Busca') },
    { path: "/prerendered/", redirect: "/404" },
    { path: '/:pathMatch(.*)*', redirect: '/404' },
];


const store = createStore({
  state () {
    return {
      //get from localstorage
      level : localStorage.getItem('level') ? localStorage.getItem('level') : 'Visitante',
      currentComponents: []
    }
  },
  getters: {
    getLevel: state => {
      return state.level
    },
    getCurrentComponents: state => {
      return state.currentComponents
    }
  },
  mutations: {
    setActiveLevel (state, level) {
      localStorage.setItem('level', level);
      state.level = level;
    },
    setCurrentComponents (state, components) {
      state.currentComponents = components;
    }
  },
  actions: {
    setActiveLevel ({ commit }, level) {
      commit('setActiveLevel', level);
    },
    setCurrentComponents ({ commit }, components) {
      commit('setCurrentComponents', components);      
    }
  }

})

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  mode: 'history',
  history: createWebHistory(),
  routes, // short for `routes: routes`
});



const app = createApp(App);


//app.mixin(metaTagMixin);
app.mixin(gtmTagsMixin);
app.mixin(Cookies);
app.use(router);
app.use(store);
app.use(VueMasonryPlugin);

app.use(VueTheMask);
app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});

app.mount("#app");