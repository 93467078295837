<template>
  <div>   
    <div v-if="component">
      <section class="contact-content">
        <div class="center-content">
          <div class="cards_list">
            <div class="card_box"  v-for="card in component.Cards" 
            :key="card.id"
            :class="{ bg_lightGrey: card.gray_background }">     
              <h2 class="t-45 t-darkBlue t-sb">
                {{ card.title }}
              </h2>
              <div 
              v-if="card.responsible_name" 
              class="">
                <p class="t-22 t-lh-25 t-darkBlue">{{ card.responsible_name }}</p>
              </div>            
              <img v-if="card.image"
              :src="api_url + card.image.data.attributes.url"                
                aria-hidden="true"
              />                      
              <div class="flexed keep-flex aic-center box_img" 
                v-for="line in card.contact_line" 
                :key="line.id">            
                    <img v-if="line.icon"
                    :src="api_url + line.icon.data.attributes.url"
                    :alt="line.icon.data.attributes.alternativeText"                
                      aria-hidden="true"
                    />
                    <p  v-if="line.text" 
                    class="t-18 t-lh-25">
                    {{ line.text }}
                    </p>
              </div>                                        
            </div>
          </div>          
        </div>       
      </section>
    </div>
  </div>
</template>
<style lang="styl" scoped>
    .contact-content{
        padding: 95px 0px 150px;

        .center-content {
          .cards_list{
              display: flex;
              flex-wrap: wrap;
              gap: 30px;
              width: 100%;
              justify-content: space-between;

              @media $tablet-max {
                justify-content: center;
              }
            .card_box{              
                border-radius: 20px;
                background:#EFEFEF;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                align-content: center;
                gap: 30px;
                flex-wrap:wrap;                
                min-height: 402px;
                padding: 40px 40px;
                width: 100%;
                max-width: 470px;
                
                h2 {
                  width: 100%;
                  font-size: 4.5rem;
                  margin-top: 10px;
                  margin-bottom: 5px;
                  text-align: center;
                  @media $large-mobile {
                    margin-bottom: 30px;
                  }
                }

                .box_img  {
                  margin-bottom: 20px;
                  @media $large-mobile {
                            flex-direction: column;
                          }
                  img {
                      padding: 0 15px;
                      @media $large-mobile {
                        margin-bottom: 20px;
                      }
                    }  
                }
                @media $large-mobile {
                  display: flex;
                  justify-content: center;
                  gap: 10px
                }
                  @media $mobile-max{
                      text-align: center;
                      height: auto;
                      width 336px;
                      padding: 40px 13px;

                      .keep-flex{
                          flex-direction: column;
                          @media $large-mobile {
                            flex-direction: column;
                          }

                          img{
                              max-width: 470px;
                              @media $mobile-max {
                                max-width: 100% !important;
                              }
                          }
                      }
                  }
              }

          } 
          
          @media $tablet-max {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }


        @media $tablet-max {
          .cards_list {
            justify-content: center;
          }
          .card_box {
            width: 100%;
            max-width: 441px;
          }
        }

        @media $mobile-max{
            .t-45{
                font-size: 30px;
            }
        }
        .form {            
          display: flex;
          align-items: flex-start;
          width: 100%;
          max-width: 470px;
          margin-top: 50px;           
        }        
    }
</style>
<script>
export default {
  name: "ComponentSectionsCard",
  props: {
    component: Object,   
  },  
  data() {
    return {
      api_url: process.env.VUE_APP_STRAPI_URL,
    };
  }    
};
</script>
