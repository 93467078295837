<template v-if="noticias">
  <Header />
  <main class="structure main-page">
    <NoticiasList :noticias="noticias" :featured="featured"></NoticiasList>
    <button @click="more" class="noticias-list__button">Carregar mais</button>
  </main>
  <Footer />
</template>
<script>
import axios from "axios";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import NoticiasList from "../components/NoticiasList.vue";
import titleMixin from "../mixins/mixinTitle";

export default {
  name: "ListNoticias",
  title() {    
    return `${this.pageTitle}`;
  
  },
  metaTags() {
    return this.metaTagsTags;
  },
  description() {
    return `${this.excerpt}`;
  },
  tags() {
    return this.TagsGTM;
  },
  cookies() {
    return this.cookie;
  }, 
  mixins: [
    titleMixin 
  ], 
  components: {    
    Header,
    Footer,
    NoticiasList,
  },
  methods: {
    more: function () {
      this.limit += this.limit;
    },
    strapi_api_url: function () {
      return process.env.VUE_APP_STRAPI_API_URL;
    },
    getNoticias: async function () {
      const params = this.$route.params;

      const api_url = process.env.VUE_APP_STRAPI_API_URL;
      let url =
        api_url + `/noticias/?sort[0]=publishedAt:desc&populate=*&pagination[limit]=${this.limit}`;

      if (params.slug) {
        const route = params.slug;
        url =
          api_url +
          `/noticias/?populate=*&[filters][categories][slug]=${route}&sort[0]=publishedAt:desc&sort[1]=createdAt`;
      }
      const res = await axios.get(url);
      const noticias = res.data;

      const pageTitle = "Leia Saúde";
      this.noticias = noticias;
      this.pageTitle = pageTitle;

      const response = {
        noticias,
        pageTitle,
      };
    

      return response;
    },
    async setMetaTags() {
      const params = this.$route.params;
      const api_url = process.env.VUE_APP_STRAPI_API_URL;
      let url = api_url + `/pages/getBySlug/leia-saude`;

      if (params.slug) {
        const route = params.slug;
        url =
          api_url +
          `/noticias/?populate=*&[filters][categories][slug]=${route}&sort[0]=publishedAt:desc&sort[1]=createdAt`;
      }
      const res = await axios.get(url);


      const head = document.head;
      const body = document.body;

      let cookies = res.data.data.attributes.COOKIE.cookies;
      if (cookies) {
        cookies.forEach((cookie) => {
          if (cookie.script !== null && cookie.script !== "") {
            head.insertAdjacentHTML("beforeend", cookie.script);
          }
        });
      }

      

      let tags = res.data.data.attributes.TAGS.tags;
      
      if (tags) {     
         tags.forEach((tag) => {
            // HEAD
            if(tag.head_gtm !== null && tag.head_gtm !== ''){
              const headGTM = document.createElement('script');
              headGTM.insertAdjacentHTML('afterbegin', tag.head_gtm);
              head.appendChild(headGTM);
            }

          // BODY
          if (tag.body_gtm !== null && tag.body_gtm !== "") {
            body.insertAdjacentHTML("afterbegin", tag.body_gtm);
          }
        });
      }
      
    },
  },
  data() {
    return {
      noticias: {},
      api_url: process.env.VUE_APP_STRAPI_URL,
      pageTitle: "",
      limit: 8,
    };
  },
  async setup() {
    const resFeatured = await axios.get(
      process.env.VUE_APP_STRAPI_API_URL +
        "/noticias/?[filters][destacar_no_banner]=true&sort[0]=publishedAt:desc&sort[1]=createdAt&populate=*"
    );

     
     const pageTitle = 'Leia Saúde | Tudo Sobre Saúde e Bem-estar';
    
    const featured = resFeatured.data;

    return {
      featured,
      pageTitle
    };
  },
  async created() {
    await this.getNoticias();
    await this.setMetaTags();
  },
  watch: {
    limit() {
      this.getNoticias();
    },
  },
};
</script>
<style lang="styl">
.noticias-list__button {
  grid-column: 1 / -1;
    padding: 15.5px 24px;
    gap: 10px;
    background: #D5086E;
    border-radius: 200px;
    color: #FFF;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    width: 301px;
    height: 48px;
    margin: 64px auto;
    border: none;
    display: flex;
    justify-content: center;
    &:hover {
      background: #f60d81;
    }
}
</style>
