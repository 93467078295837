<template>
    <section class="information" aria-label="Informações Amil One">
        <div class="center-content">       
          <div class="title-section">            
            <h2 v-if="component.title">
              {{component.title}}
            </h2>           
          </div>
          <div class="description-news" v-html="component.description">
          </div>                  
          <div class="noticias__list">
            {{ noticia }}
            <div v-for="noticia in noticias" class="noticias_featured__list_item" :key="noticia.id">
              <div class="noticias_featured__list_item_image_wrap">
                <img :src="api_url + noticia.attributes.featured_image.data.attributes.url" :alt="noticia.attributes.featured_image.data.attributes.alternativeText" >
              </div>
              <div class="noticias_featured__list_item_content">
                <div class="noticias_featured__list_item_category" aria-label="Categoria Noticia">
                  {{noticia.attributes.categories.data[0].attributes.name}}
                </div>              
                <h3 class="noticias_featured__list_item_title">
                  {{noticia.attributes.title}}
                </h3>
                <div class="noticias_featured__list_item_description" v-html="noticia.attributes.excerpt">
                </div>
                <a :href="'/noticias/' + noticia.attributes.slug" aria-label="Ver mais noticias">Continuar lendo</a>
                <div class="noticias_featured__list_item_footer">
                  <figure>
                    <img src="@/images/selo.png" alt="Imagem Selo de Qualidade Amil One" width="50">
                  </figure>
                  <span aria-label="Data Da Publicação">{{ formatDate(noticia.attributes.createdAt) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>       
      </section>
</template>
<style lang="styl" scoped>
.information {
  background: #F6F6F6;
  padding-top: 10PX;;
}
  .title-section {
    text-align: left;
    margin-top: 50px;
    @media $large-mobile {
        padding: 15px 20px;
        text-align: left;
    }
    @media $mobile-max {
      padding: 0;
      margin-top: 0;
    }
    h2 {
      color: #3B3B3A; 
      font-weight: 500;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 30px;
    }
  }
  .noticias__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    padding-bottom: 30px;
    .noticias_featured__list_item {
      display: flex;
      background: #FFFF;
      border-radius: 16px;
      padding-right: 20px;
      @media $large-mobile {
        padding-right: 0;
      }
      .noticias_featured__list_item_image_wrap {
        width:276px;
        height:100%;
        flex-shrink: 0;
        border-radius: 16px 0 0 16px;
        overflow: hidden;
        @media $large-mobile {
          height: auto;
          width: auto;
        }
        img {
          object-fit:cover;
          width:100%;
          height:100%;
          @media $large-mobile {
            border-radius: 16px;  
          }
        }

      }
      @media $large-mobile {
        flex-direction: column;
      }            
      .noticias_featured__list_item_content {
        padding-top: 25px;
        padding-left: 20px;
        .noticias_featured__list_item_category {
          padding: 4px 12px;
          background: #D5086E;
          border-radius: 4px;
          color: #FFF;
          display: inline;
          font-size: 12px;
        }
        .noticias_featured__list_item_title {
          font-weight: 500;
          font-size: 18px;
          line-height: 1.2;
          margin-top: 20px;
        }
        .noticias_featured__list_item_description {
          font-size: 16px;
          line-height: 24px;
          color: #595758;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        a {
          color: #D5086E;
          font-weight: 500;
          font-size: 16px;
          line-height: 17px;
          text-decoration: underline;
        }
      }
      .noticias_featured__list_item_footer {
        padding: 17px 0;
        margin-top: 25px;
        border-top: 1px solid #D9D9D9;
        display: flex;
        align-items: center;
        gap: 80px;
        color: #595758;
        figure {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }
</style>
<script>
import axios from 'axios';

export default {
    
    name: "ComponentSectionsSlider",    
    props: {
        component: Object,
    },
    methods: {
        strapi_api_url: function() {
            return process.env.VUE_APP_STRAPI_API_URL;
        },
        getNoticias: async function() {
            const api_url = this.strapi_api_url();
            
            let filters =`/noticias?pagination[pageSize]=1&pagination[page]=1`;
            const categories = this.component.categories.data.map(category => {
                filters += `&filters[categories]=${category.id}`;
            })                            
            
            let noticias ='';
            if(categories) {
                await axios.get(`${api_url}${filters}`).then(response => {
                    noticias = response.data.data;
                });
            } 
           
          
            const res = {
                noticias                
            }                   
            this.teste = '3';
            this.noticias = noticias;             
            return res;
        },
        formatDate(date) {
          const dateObj = new Date(date);
            return dateObj.toLocaleDateString("pt-BR", {
                year: "numeric",
                month: "long",
                day: "numeric",                  
            })
        },
        async setup() {
            const noticias = await this.getNoticias();
            const styles = `
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            `;
            
            return {
                styles,
                noticias: noticias.noticias,
            }
        }
    
    },
    async mounted() {
        const data = await this.setup();        
        this.noticias = data.noticias;                
        this.teste = '2';
    },
    data() {           
        return {
            teste: '1',
            noticias: {},
            styles: {},
            api_url: process.env.VUE_APP_STRAPI_URL,
            banners: {},
            settings: {
                itemsToShow: 1,
            },            
        };
    },    
}
    
</script>


