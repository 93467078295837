<template>
    <section  v-if="component" class="reembolso">
        <div class="component-reembolso" v-for="step in component.Steps" :key="step" :class="{ gray_background: step.gray_background }">
            <div class="center-content" ref="wrapperParagraph">
                <h2 v-if="step.title">{{ step.title }}</h2>
                <div class="container" :class="{ to_reverse: step.text_right }">          
                    <div class="content-reembolso">
                        <img v-if="step.icon.data" :src="api_url + step.icon.data.attributes.url" class="image-icon" ref="image_bg"> 
                        <div v-if="step.text" v-html="step.text" class="text-primary"></div>
                        <div v-if="step.text_featured" v-html="step.text_featured" class="text-featured"></div>
                    </div>
                    <img v-if="step.image.data.attributes.url" :src="api_url + step.image.data.attributes.url" :alt="step.alternativeText" class="img-principal">  
                </div>
            </div>    
        </div> 
    </section> 
</template>
<style lang="styl"> 
.reembolso {
    padding-bottom: 50px;
    background: #FFF;
}
.component-reembolso {
    background: #FFF;
    .center-content {
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center;
        .container {
            padding-top: 80px;
            display: flex;
            justify-content: space-between;
            gap: 150px;
            text-align: center;
            @media $large-mobile {
                flex-direction: column;
                align-items: center;
                gap: 23px;
            }
            .content-reembolso {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 20px;
                    .text-primary {
                        line-height: 40px;
                        font-weight: 400;
                        font-size: 20px;
                        @media $mobile-max {
                            font-size: 18px;
                        }
                        ul {
                            list-style-type: disc;
                            list-style-position: inside;
                            li {
                                line-height: 40px;
                                font-size: 20px;
                                margin-bottom: 0;
                            }
                        }
                    }
                    .text-featured {
                        background: #D5086E;
                        color: #fff;
                        padding: 12px 16px;
                        border-radius: 8px;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 28px;
                    }
                }
                .image-icon {
                    max-width: 107px;
                }
            }
            .img-principal {
                max-width: 532px;
                max-height: 532px;
                @media $mobile-max {
                    max-width: 100%;
                }
            }
        }
} 
</style>
<script>
export default {   
    name: "ComponentSectionsParagraph",
    props: {
        component: Object
    },
    data() {        
        return {            
            api_url: process.env.VUE_APP_STRAPI_URL,
        };
    },  
    mounted() {
        this.calculateImageSize();
        //on resize calculate image size again
        window.addEventListener('resize', this.calculateImageSize);
    },
    methods: {
        calculateImageSize() {
            
            //get image bg size
            const image_bg = this.$refs.image_bg; 
            if(image_bg.src) {
                const image = new Image();
                image.src = image_bg.src;
                    
                image.onload = () => {
                    const image_bg_width = image.naturalWidth;
                    const image_bg_height = image.naturalHeight;        
                    const wrapper = this.$refs.wrapperParagraph;
                    const proportion = image_bg_height / image_bg_width;                
                    const document_width = document.documentElement.clientWidth;                
                    const new_image_width = document_width * 0.65;
                    const new_image_height = new_image_width * proportion;                    
                    wrapper.style.height = new_image_height + 'px';                
                }
            }
            
        }
    }
}
</script>


