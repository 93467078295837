<template>    
    <a target="_blank" aria-label="Cards Amil One" v-if="component" :href="component.link" class="card_link">
      <div class="card" 
      :data-id="component.data_id"
      :style="{ height: component.altura + 'px', width: component.largura + 'px' }">
        <img v-if="component.icon.data" :src="api_url + component.icon.data.attributes.url" alt="Cards Amil">
        <h3 v-if="component.title" class="card__title" v-html="component.title"></h3>
        <div v-if="component.text" class="card__text" v-html="component.text"></div>
      </div>    
    </a>       
</template>
<style lang="styl">
  
    .card_link {
        text-decoration: none !important;
        @media $mobile-max {
          width: 100%; 
          display: flex;
          justify-content: center;
        }        
    }
    .card {        
        background: #FFFFFF;
        border-radius: 24px;
        display: flex;
        color: #3B3B3A;
        flex-direction: column;
        justify-content: flex-start;
        padding: 24px, 12px, 24px, 12px;
        gap: 10px;
        @media $mobile-max {
          height: 257px;
          gap: 10px;
        }
        &:hover {
            img {
              filter: brightness(100);
            }
            .card__title {
              color: #FFF;
            }
            background: #D5086E !important;
            color: #FFF !important;
            @media $large-mobile {
              background: #FFFFFF !important;
              img {
                filter: brightness(1) !important;
              }
              .card__title,
              .card__text {
                color: #3B3B3A !important;
              }
            }
        }
    }
    .card__text {
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        @media $mobile-max {
            line-height: 22px;
            font-size: 13px;
        }
    }
    .card__title {
        font-weight: 700;
        font-size: 16px;        
        @media $mobile-max {
          font-size: 13px;
        }      
    }
</style>
<script>

export default {
  name: "ComponentCard",
  props: {
    component: Object,
  },
  data() {
    return {
      api_url: process.env.VUE_APP_STRAPI_URL,
    };
  },
};
</script>