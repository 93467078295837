<template>    
    <a v-if="component" :href="component.link" class="card_link" title="Card link">
        <a :href="component.url" class="circle__url" title="card link">
            <div class="circles">
                <div class="circle__img_wrapper" >
                    <img v-if="component.image.data" :src="api_url + component.image.data.attributes.url" :alt="component.alternativeText" class="circle__img_wrapper_desktop"
                    width="209"
                    height="209">                   
                </div>                
                <div class="circle__text">
                    <h3 class="circle__title">{{component.title}}</h3>                
                </div>            
                           
            </div>
        </a>
    </a>   
</template>
<style lang="styl">   
    .card_link {
        margin: 0 auto;
    } 
    a.circle__url {
        color: #FFF;
        text-decoration: none;        
    }
    .circle__title {
        color: #FFF;
        font-weight: 500;
        font-size: 18px;
        line-height: 19px;
        margin-top: 50px;
        @media $mobile-max {
            margin-top: 50px;
        }
    }
    .circle__img_wrapper { 
        background:#fff;
        border:5px solid #D5086E;
        border-radius:50%;
        margin: 0 auto;
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
        @media $large-mobile {
            width: 194.17px;
            height: 194.17px;
        }
        img {
            width: 100%;
            padding: 6px;
        }
    }
    .cirlce__text {
        text-align:center;
    }
    
</style>
<script>

export default {
  name: "ComponentCard",
  props: {
    component: Object,
  },
  data() {
    return {
      api_url: process.env.VUE_APP_STRAPI_URL,
      url: '',
    };
  },
};
</script>