<template>
    <section class="information">
        <div class="center-content">       
          <div class="title-section">            
            <h2 v-if="component.title">
              {{ component.title }}
            </h2>           
          </div>
          <div class="description-news">
          </div>                  
          <div class="noticias-viajantes">           
            <div class="noticias-viajantes__list_item">
              <div class="noticias-viajantes__list_item_image_wrap">
                <img :src="api_url + component.noticias.data[0].attributes.featured_image.data.attributes.url" :alt="component.noticias.data[0].attributes.featured_image.data.attributes.alternativeText" >
              </div>
              <div class="noticias-viajantes__list_item_content">
                <div class="noticias-viajantes__list_item_category">
                    {{ component.noticias.data[0].attributes.categories.data[0].attributes.name  }}
                </div>              
                <h3 class="noticias-viajantes__list_item_title">
                  {{ component.noticias.data[0].attributes.title }}
                </h3>
                <div class="noticias-viajantes__list_item_description" v-html="component.noticias.data[0].attributes.excerpt">
                </div>
                <a :href="'/noticias/' + component.noticias.data[0].attributes.slug">Continuar lendo</a>
                <div class="noticias-viajantes__list_item_footer">
                  <figure>
                    <img src="@/images/selo.png" alt="Selo Amil One" width="50">
                    <figcaption>Amil One</figcaption>
                  </figure> 
                </div>
              </div>
            </div>
          </div>
        </div>       
      </section>    
</template>
<style lang="styl" scoped>
.information {
  background: #F6F6F6;
}
.title-section {
    text-align: left;
    margin-top: 50px;
    @media $large-mobile {
        padding: 15px 20px;
        text-align: left;
    }    
    h2 {
      color: #3B3B3A; 
      font-weight: 500;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 30px;
    }
  }
  .noticias-viajantes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    padding-bottom: 30px;
    .noticias-viajantes__list_item {
      display: flex;
      background: #FFFF;
      border-radius: 16px;
      padding-right: 20px;
      @media $large-mobile {
        padding-right: 0;
      }
      .noticias-viajantes__list_item_image_wrap {
        width:276px;
        height:100%;
        flex-shrink: 0;
        border-radius: 16px 0 0 16px;
        overflow: hidden;
        @media $large-mobile {
          height: auto;
          width: auto;
        }
        img {
          object-fit:cover;
          width:100%;
          height:100%;
          @media $large-mobile {
            border-radius: 16px;  
          }
        }

      }
      @media $large-mobile {
        flex-direction: column;
      }            
      .noticias-viajantes__list_item_content {
        padding-top: 25px;
        padding-left: 20px;
        .noticias-viajantes__list_item_category {
          padding: 4px 12px;
          background: #D5086E;
          border-radius: 4px;
          color: #FFF;
          display: inline;
          font-size: 12px;
        }
        .noticias-viajantes__list_item_title {
          font-weight: 500;
          font-size: 18px;
          line-height: 1.2;
          margin-top: 20px;
        }
        .noticias-viajantes__list_item_description {
          font-size: 16px;
          line-height: 24px;
          color: #595758;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        a {
          color: #D5086E;
          font-weight: 500;
          font-size: 16px;
          line-height: 17px;
          text-decoration: underline;
        }
      }
      .noticias-viajantes__list_item_footer {
        padding: 17px 0;
        margin-top: 25px;
        border-top: 1px solid #D9D9D9;
        display: flex;
        align-items: center;
        gap: 80px;
        color: #595758;
        figure {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }

</style>
<script>
export default {
  
  name: "ComponentSectionsSlider",    
  props: {
      component: Object,
  },
  methods: {
      strapi_api_url: function() {
          return process.env.VUE_APP_STRAPI_API_URL;
      },
  },  
  data() {           
      return {
          teste: '1',
          noticias: {},
          styles: {},
          api_url: process.env.VUE_APP_STRAPI_URL,
          banners: {},
          settings: {
              itemsToShow: 1,
          },            
      };
  },    
}
  
</script>


