<template>
    <div class="component-slider-banners" aria-label="Slider Banner Amil One">
        <div class="container">
            <div class="slider" ref="wrapper">
                <div class="slider__item" v-for="(slider,index) in component.banners" :key="slider" :data-index="index+1" :class="slider.modelo">
                    <picture>
                        <source v-if="slider.background_image_mobile && slider.background_image_mobile.data" media="(max-width:599px)" :srcset="api_url + slider.background_image_mobile.data.attributes.url">
                        <img v-if="slider.background_image && slider.background_image.data" :alt="slider.alternativeText" :src="api_url + slider.background_image.data.attributes.url" class="slider__item_bg" />
                    </picture>
                    <a :href="slider.link_url ? slider.link_url : '#'" :style="{ cursor: slider.link_url ? 'pointer' : 'default' }" :target="isExternal(slider.link_url) ? '_blank' : null">
                        <div class="slider__item_info">
                            <div class="slider__item_info_text">
                                <h2 v-if="slider.title" class="slider__item_title" v-html="slider.title"></h2>
                                <div v-if="slider.text" class="slider__text" :style="{ color: slider.color ? slider.color : '#fff' }" v-html="slider.text"></div>
                                <a v-if="slider.button_link" :href="slider.button_link" :style="{ color: slider.color ? slider.color : '#fff' }" class="slider__item_link" :target="isExternal(slider.button_link) ? '_blank' : null">{{slider.button_text}}</a>
                            </div>
                            <picture>
                                <source v-if="slider.background_element_mobile && slider.background_element_mobile.data" media="(max-width:599px)" :srcset="api_url + slider.background_element_mobile.data.attributes.url">
                                <img v-if="slider.background_element && slider.background_element.data" :src="api_url + slider.background_element.data.attributes.url" class="slider__item_bg_element" alt="Background Elemento Destkop" />
                            </picture>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="">
        <div class="slider_controls">
            <div class="slider_doots">
                <ul ref="dots">
                    <li  :class="{ active : pages == 1}" v-for="(pages,index) in pagination" :key="pages" @click="setActiveSlide(pages)" :data-banner="index+1">
                        <span class="dots-inner"></span>
                    </li>
                </ul>
            </div>
            <button @click="pauseSlide()" aria-label="Pausar slide"><img :src="require(`@/images/${imgURL}`)" alt="Botão de pausar slide" height="38"></button>
        </div>
    </div>
</template>
<style lang="styl">
.component-slider-banners {
    background:  #F6F6F6;
    .container {
        background:  #F6F6F6;
        @media $large-mobile {
            width: 100%;
            max-width: 100%;
        }
    }
}

.component-slider-banners {
    padding: 29px 0 20px 0;
    overflow: hidden;
    @media $large-mobile {
        margin: 40px 0 20px 0;
        padding:0;
    }

}


.slider__text {
    font-size: 14px;
    @media $mobile-max {
        font-size: 12px;
    }
    @media $large-mobile {
        font-size: 10px;
    }
    ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 11px;
        @media (min-width: 770px) {
            gap: 4px;
        }
        @media $mobile-max {
            gap: 0 !important;
        }
        li {
            display: flex;
            align-items: center;
            margin-bottom: 0 !important;
            font-size: 10px !important;
            &::before {
                background: url(../../images/check.svg) no-repeat center;
                border-radius: 50%;
                content:"";
                display: inline-flex;
                height: 25px;
                margin-right: 6px;
                width: 25px;
                background-size: 25px;
                @media $mobile-max {
                    background-size: 14px;
                    width: 14px;
                    height: 14px;
                    margin: 5px;
                }
                @media (min-width: 770px) {
                    background-size: 19px;
                    width: 19px;
                    height: 19px;
                    margin: 5px;
                }
            }
            span {
                @media $mobile-max {
                    font-size: 10px !important;
                } 
            }
            @media $mobile-max {
                gap: 0 !important;
                font-size: 10px !important;
            }
            @media $large-mobile {
                font-size: 8px;                
            }
        }
    }
}

.slider_controls {
    background:  #F6F6F6;
    display:flex;
    justify-content:center;
    gap:10px;
    padding:0px 0;
    align-items: center;
    button {
        background: none;
        border: none;
        img {
            height: 38px;
        }
    }
}
.slider_doots {
    ul {
    display: flex;
    color: #F2B5D4;
    gap: 5px;
    justify-content: center;
    li {
        width: 13px;
        height: 10px;
        position: relative;
        cursor: pointer;
        background: #F2B5D4;
        border-radius: 50px;
        transition: .3s;
        margin-bottom: 0 !important;
        &.active {
            width: 38px;
            .dots-inner {
                content: '';
                display: inline-block;
                background #D5086E;
                width: 13px;
                height: 10px;
                position: absolute;
                left: 0;
                cursor: pointer;
                border-radius: 50px;
                animation: fill 5s;
                animation-fill-mode: forwards;
            }
        }
    } 
    }
}
@keyframes fill {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}
.slider {
    display:flex;
    width:100%;
    height:auto;    
    min-height: 320px;
    align-items:center;
    justify-items:center;   
    justify-content:space-between;
    @media $large-mobile {
        padding: 8px;
        gap: 10px;
    }
    .slider__item {
        position:relative;  
        flex-shrink:0;  
        width:378px;
        height:198px;    
        overflow:hidden;
        border-radius:16px; 
        transition: width 0.2s linear, height 0.2s linear;     
        display:none;   
        width: 40%;
        @media (min-width: 1000px) and (max-width: 1115px) {
            width: 41%;
        }
        &.slider__item--active  {                 
            width:786px;
            height:292px;
            width:58%;
            @media (min-width: 761px) and (max-width: 1115px) {
                width: 58%;
            }
            @media (min-width: 100px) and (max-width: 760px) {
                width: 100%;
            }
            @media $mobile-max {
                width: 100%;
                height: calc((100vw - 20px) * 0.6);
                max-height: 292px;            
            }
            .slider__item_info_text {
                padding:0 20px;
                @media $small-mobile-max {
                    .slider__item_title {
                        font-size:16px;
                        p {
                            font-size:14px;
                        }
                    }
                }      
                
            }
        }

        // PREVIEW
        .slider__item_bg {
            position:absolute;
            height:100%;
            width: auto;
            right:0;
            top:0;
            max-width:none;   
            transition: all .5s;     

        }
        .slider__item_info {
            position:absolute;        
            top:0;
            left:0;        
            text-align:center;        
            height:100%;
            width:100%;
            .slider__item_info_text {
                padding:0 15px;
                position:absolute;
                z-index:1;
                top:50%;
                left:0;
                text-align:left;
                transform:translateY(-50%);
            }
            .mobile {
                display: none;
                @media $mobile-max {
                    display: block;
                }
            }
            .slider__item_bg_element {
                position:absolute;
                top:0;
                left:0;
                height:100%;
                z-index:0;    
            }
            .slider__item_title {
                font-size: 18px;            
                font-weight: 700;
                line-height:1.2em;                    
                display:inline-block;
                transition: all 0.2s linear;
                @media $large-mobile {
                    /* font-size: 12px !important; */
                }
            }       
            .slider__item_link{
                padding-top:15px;
                transition: all 0.2s linear;   
                @media $large-mobile {
                    font-size: 14px !important;
                }
            }
        }

    }               

}

.Modelo-01 {
    &:nth-child(-n+2) {
        display:block;
        opacity:1;
    }
    &.slider__item--active  { 
        .slider__item_bg_element {
            @media (min-width: 100px) and (max-width: 999px) {
                left: 43px;
            }
        }
        .slider__item_info {
            .slider__item_title {
                font-size:20px;
            } 
            .slider__item_info_text {
                max-width:45%;
                @media $small-mobile-max {
                    max-width:50%;
                }
                .slider__item_title {
                    font-size: 20px;                                        
                    font-weight: 700;
                    margin-bottom: 20px;
                    p {
                        strong {
                            color: #D5086E !important;
                        }
                    }                    
                                   
                }
                
            }            
        }
        /* .slider__item_link {
            padding-top: 15px !important;
            @media (min-width: 770px) {
                font-size: 16px !important;
            }
            &:after {
                right:-15px;
                top: 16px !important;
            }

        } */
        .slider__item_link{
            position:relative;
            padding-top: 0 !important;
            font-size: 16px !important;
            &::after {
                content: '>';
                display: block;
                color: #D5086E;
                position: absolute;
                top: 2px !important;
                right:-10px;                        
            }
        }
        .slider__item_bg {
            right:0;
            transition: all .5s;
        }
        .slider__text {
            font-size: 16px !important;
            margin-bottom: 20px;
            @media $large-mobile {
                font-size: 15px !important;
            }
            @media $small-mobile-max {
                font-size: 12px !important;
            }
        }
        li {
            font-size: 16px !important;    
            @media $large-mobile {
                font-size: 15px !important;                
            }
            &::before {
                @media (min-width: 770px) {
                    background-size: 25px;
                    width: 25px;
                    height: 25px;
                    margin: 5px;
                }
                @media $mobile-max {
                    background-size: 15px;
                    width: 15px;
                    height: 15px;
                    margin: 5px;
                }
            }
        }     
    
    }

    // PREVIEW
    .slider__item_info {
        position:absolute;        
        top:0;
        left:0;        
        text-align:center;        
        height:100%;
        width:100%;
        .slider__item_info_text {
            padding:0 15px;
            position:absolute;
            z-index:1;
            top:50%;
            left:0;
            max-width:50%;
            text-align:left;
            transform:translateY(-50%);
        }
        .mobile {
            display: none;
            @media $mobile-max {
                display: block;
            }
        }
        .slider__item_bg_element {         
            @media (min-width: 599px) and (max-width: 1118px) {
                left:-43px;
            }
            @media (min-width: 599px) and (max-width: 840px) {
                left: -11%;
            }
        }
        .slider__item_title {
            font-size: 18px;            
            font-weight: 700;
            line-height:1.2em;                    
            display:inline-block;
            transition: all 0.2s linear;
            @media $large-mobile {
                font-size: 12px !important;
            }
        }       
        .slider__item_link{
            font-size: 12px;
            line-height:1.2em; 
            @media $large-mobile {
                font-size: 14px !important;
            }
        }
    }
    /* @media $mobile-max {
        max-width:100%;
    } */
}

.Modelo-02 {
    .slider__item_link{
        background: #D5086E;
        font-size: 12px !important;
        position:relative;
        padding-top: 0 !important;
    }   
    &:nth-child(-n+2) {
        display:block;
        opacity:1;
    }
    &.slider__item--active  {
        .slider__item_bg_element {
            @media (min-width: 100px) and (max-width: 999px) {
                left: 43px;
            }
        }
        .slider__item_info {
            .slider__item_title {
                font-size: 30px !important;
                margin-bottom: 20px;
                color:#D5086E;
                @media $mobile-max {
                    font-size: 25px !important;
                }
            } 
            .slider__item_info_text {
                max-width: 100%;
                right: 0;
                @media $small-mobile-max {
                    max-width: 100%;
                    .slider__item_title {
                        font-size:16px !important;
                        p {
                            font-size:14px !important;
                        }
                    }
                }
                .slider__item_title {
                    font-size: 20px;                                        
                    font-weight: 700; 
                    p {
                        strong {
                            color: #D5086E !important;
                        }
                    }                    
                                   
                }       
                
            }            
        }
        .slider__item_link {
            width: 210px !important;
            height: 35px !important;
            @media (min-width: 770px) {
                font-size: 16px !important;
            }
            @media $mobile-max {                
                width: 188px;
                height: 23px;
                font-size: 16px;
            }
            @media $small-mobile-max {
                width: 180px !important;
                height: 22px !important;
            }
            &:after {
                right:-15px;
                top: 16px !important;
            }

        }
        .slider__item_bg {
            right:0;
            transition: all .5s;
        }
        .slider__box-text {
            display: flex;
            gap: 20px;
            margin-bottom: 20px;
            img {
                width: 82px !important;
                height: 78px !important;
                @media $mobile-max {
                    width: 71px;
                    height: 66px;
                }
                @media $small-mobile-max {
                    width: 48px !important;
                    height: 45px !important;
                }
            }
            .slider__text {
                font-size: 23px !important;
                @media $large-mobile {
                    font-size: 18px !important;
                }
                @media $small-mobile-max {
                    font-size: 12px !important;
                }
            }
        }

        li {
            font-size: 16px !important;    
            @media $large-mobile {
                font-size: 15px !important;                
            }
            &::before {
                @media (min-width: 770px) {
                    background-size: 25px;
                    width: 25px;
                    height: 25px;
                    margin: 5px;
                }
                @media $mobile-max {
                    background-size: 15px;
                    width: 15px;
                    height: 15px;
                    margin: 5px;
                }
            }
        }
      
    
    }

    // PREVIEW
    .slider__item_bg {
        width: 100% !important;
    }
    .slider__item_info {
        position:absolute;        
        top:0;
        left:0;        
        text-align:center;        
        height:100%;
        width:100%;
        
        .slider__item_info_text {
            right: 0;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        .mobile {
            display: none;
            @media $mobile-max {
                display: block;
            }
        }
        .slider__box-text {
            display: flex;
            gap: 20px;
            margin-bottom: 20px;
            @media $large-mobile {
                gap: 5px;
            }
            img {
                width: 48px;
                height: 45px;
                @media $mobile-max {
                    width: 71px;
                    height: 66px;
                }
            }
            .slider__text {
                font-size: 13px;
                /* @media $large-mobile {
                    font-size: 18px;
                } */
                @media $small-mobile-max {
                    font-size: 12px !important;
                }
            }
        }
        .slider__item_bg_element {
            width: 100%;
            right: 0; 
        }
        .slider__item_title {
            font-size: 18px;            
            font-weight: 700;
            line-height:1.2em;                    
            display:inline-block;
            color:#D5086E;
            transition: all 0.2s linear;
            margin-bottom: 20px;
            @media $large-mobile {
                font-size: 12px !important;
            }
        }       
        .slider__item_link{
            background: #D5086E;
            width: 180px;
            height: 22px;
            color: #FFF !important;
            font-size: 11px;
            border-radius: 6px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s linear;   
            @media $large-mobile {
                font-size: 14px;
            }
            @media $mobile-max {                
                width: 188px;
                height: 23px;
                font-size: 16px;
            }
        }
    }
    /* @media $mobile-max {
        max-width:100%;
    } */
}


.Modelo-03 {
    .slider__item_link{
        background: #D5086E;
        font-size: 12px !important;
        position:relative;
        padding-top: 0 !important;
    }   
    &:nth-child(-n+2) {
        display:block;
        opacity:1;
    }
    &.slider__item--active  {
        .slider__item_bg_element {
            @media (min-width: 100px) and (max-width: 999px) {
                left: 43px;
            }
        }
        .slider__item_info {
            .slider__item_title {
                font-size: 30px !important;
                margin-bottom: 20px;
                color:#D5086E;
                @media $mobile-max {
                    font-size: 25px !important;
                }
            } 
            .slider__item_info_text {
                max-width: 100%;
                right: 0;
                @media $small-mobile-max {
                    max-width: 100%;
                    .slider__item_title {
                        font-size:16px !important;
                        
                        p {
                            font-size:14px !important;
                        }
                    }
                }
                .slider__item_title {
                    font-size: 20px;                                        
                    font-weight: 700; 
                    p {
                        strong {
                            color: #D5086E !important;
                        }
                    }                    
                                   
                }       
                
            }            
        }
        
        .slider__item_link {
            width: 210px !important;
            height: 35px !important;
            @media (min-width: 770px) {
                font-size: 16px !important;
            }
            @media $mobile-max {                
                width: 188px;
                height: 23px;
                font-size: 16px;
            }
            @media $small-mobile-max {
                width: 180px !important;
                height: 22px !important;
            }
            &:after {
                right:-15px;
                top: 16px !important;
            }

        }
        .slider__item_bg {
            right:0;
            transition: all .5s;
        }
        .slider__box-text {
            display: flex;
            gap: 20px;
            flex-direction: column;
            margin-bottom: 20px;
            .slider__text {
                font-size: 20px !important;
                text-align: center !important;
                max-width: auto !important;
                @media $large-mobile {
                    font-size: 18px !important;
                }
                @media $small-mobile-max {
                    font-size: 12px !important;
                }
            }
        }

        .slider__text-secondary {
            display: flex;
            max-width: auto !important;
            justify-content: center;
            color: #FFF !important;
            align-items: center;
            gap: 14px;
            @media (min-width: 770px) {
                /* max-width: 423px; */
                margin: 10px auto;
                gap: 0;
            }
            ul {
                li {
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    margin-bottom: 0 !important;
                    @media $mobile-max {
                        font-size: 10px !important;
                    }
                    &::before {
                        background: url(../../images/check-white.svg) no-repeat center;
                        border-radius: 50%;
                        content:"";
                        display: inline-flex;
                        height: 25px !important;
                        margin-right: 6px;
                        width: 25px !important;
                        background-size: 25px !important;
                        
                    @media (max-width: 523px) {
                        background-size: 14px !important;
                        width: 14px !important;
                        height: 14px !important;
                        margin: 5px;
                    }
                   
                        
                        @media (min-width: 770px) {
                            background-size: 19px;
                            width: 19px !important;
                            height: 19px !important;
                            margin: 5px !important;
                            background-size: 19px !important;
                        }
                    }
                }     
            }
            p {
                font-weight: bold;
                font-size: 18px !important;
                display: flex;
                gap: 13px;
                align-items: center;
                &::before {
                    background: url(../../images/cuidado-white.svg) no-repeat center;
                    border-radius: 50%;
                    content:"";
                    display: inline-flex;
                    height: 58px !important;
                    margin-right: 6px;
                    width: 76px !important;
                    background-size: 58px !important;                    
                    @media $large-mobile {
                        width: 70px;
                        height: 48px;
                        background-size: 48px;
                    }
                    @media $mobile-max {
                        background-size: 14px;
                        width: 14px;
                        height: 14px;
                        margin: 5px;
                    }
                    @media (min-width: 770px) {
                        background-size: 19px;
                        width: 19px;
                        height: 19px;
                        margin: 5px;
                    }
                    
                    @media (max-width: 523px) {
                        width: 39px !important;
                        height:  39px !important;
                        background-size:  39px !important;
                    }
                }
                @media $large-mobile {
                    font-size: 15px;
                }
                @media $mobile-max {
                    font-size: 10px !important;
                    gap: 0 !important;
                }
            }
        }

        li {
            font-size: 16px !important;    
            @media $large-mobile {
                font-size: 15px !important;                
            }
            &::before {
                @media (min-width: 770px) {
                    background-size: 25px;
                    width: 25px;
                    height: 25px;
                    margin: 5px;
                }
                @media $mobile-max {
                    background-size: 15px;
                    width: 15px;
                    height: 15px;
                    margin: 5px;
                }
            }
        }
      
    
    }

    // PREVIEW
    .slider__item_bg {
        width: 100% !important;
    }
    .slider__item_info {
        position:absolute;        
        top:0;
        left:0;        
        text-align:center;        
        height:100%;
        width:100%;
        
        .slider__item_info_text {
            right: 0;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        .mobile {
            display: none;
            @media $mobile-max {
                display: block;
            }
        }
        .slider__box-text {
            display: flex;
            gap: 10px;
            margin-bottom: 12px;
            flex-direction: column;            
            width: 75%;
            @media $large-mobile {
                gap: 5px;
            }
            
            @media (max-width: 520px) {
                width: 100% !important;
            }
            img {
                width: 48px;
                height: 45px;
                @media $mobile-max {
                    width: 71px;
                    height: 66px;
                }
            }
            .slider__text {
                font-size: 13px;
                /* max-width: 348px; */
                margin-bottom: 11px !important;
                margin: 0 auto;
                text-align: center;
                @media (min-width: 770px) {
                    font-size: 11px;
                }
                @media (max-width: 523px) {
                    font-size: 13px !important;
                    margin-bottom: 0 !important;
                }
                /* @media $small-mobile-max {
                    font-size: 12px !important;
                } */
            }
            .slider__text-secondary {
                display: flex;
                justify-content: center;
                color: #FFF !important;
                align-items: center;
                gap: 7px;
                margin: 0 auto !important;
                ul {
                    li {
                        display: flex;
                        align-items: center;
                        font-size: 10px;
                        margin-bottom: 0 !important;
                        &::before {
                            background: url(../../images/check-white.svg) no-repeat center;
                            border-radius: 50%;
                            content:"";
                            display: inline-flex;
                            height: 10px;
                            margin-right: 6px;
                            width: 10px;
                            background-size: 10px;
                            
                        }
                    }     
                }
                p {
                    font-weight: bold;
                    font-size: 10px;
                    gap: 0;
                    display: flex;
                    align-items: center;
                    &::before {
                        background: url(../../images/cuidado-white.svg) no-repeat center;
                        border-radius: 50%;
                        content:"";
                        display: inline-flex;
                        height: 40px;
                        margin-right: 6px;
                        width: 57px;
                        background-size: 40px;
                        @media $large-mobile {
                            width: 70px;
                            height: 48px;
                            background-size: 48px;
                        }
                        @media $mobile-max {
                            background-size: 14px;
                            width: 14px;
                            height: 14px;
                            margin: 5px;
                        }
                        @media (min-width: 770px) {
                            background-size: 19px;
                            width: 19px;
                            height: 19px;
                            margin: 5px;
                        }
                    }
                }
            }
            .slider__box-text {
                display: flex;
                gap: 20px;
                flex-direction: column;
                margin-bottom: 20px;
            }
        }
        .slider__item_bg_element {
            width: 100%;
            right: 0; 
        }
        .slider__item_title {
            font-size: 18px;            
            font-weight: 700;
            line-height:1.2em;                    
            display:inline-block;
            color:#D5086E;
            transition: all 0.2s linear;
            margin-bottom: 20px;
            @media (max-width: 523px) {
                margin-bottom: 5px !important;
                font-size: 18px !important;
            }
        }       
        .slider__item_link{
            background: #D5086E;
            width: 135px;
            height: 22px;
            color: #FFF !important;
            font-size: 11px;
            border-radius: 6px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s linear;   
            @media $large-mobile {
                font-size: 14px;
            }
            @media $mobile-max {                
                width: 188px;
                height: 23px;
                font-size: 16px;
            }
        }
    }
    /* @media $mobile-max {
        max-width:100%;
    } */
}

.Modelo-04 {
    &:nth-child(-n+2) {
        display:block;
        opacity:1;
    }
    &.slider__item--active  { 
        
        .slider__item_bg_element {
            @media (min-width: 100px) and (max-width: 999px) {
                left: 43px;
            }
        }
        .slider__item_info {
            .slider__item_title {
                font-size:20px;
            } 
            .slider__item_info_text {
                max-width:45%;
                @media $small-mobile-max {
                    max-width:50%;
                }
                .slider__item_title {
                    font-size: 26px;                                        
                    font-weight: 700;
                    margin-bottom: 10px;
                    @media (min-width: 770px){
                        font-size: 25px !important;
                        line-height: 26px !important;
                    }
                    @media (min-width: 523px) {
                        font-size: 26px !important;
                    }
                    @media (max-width: 523px) {
                        font-size: 15px !important;
                    }
                    @media (max-width: 350px) {
                        font-size: 12px !important;
                    }
                    p {
                        strong {
                            color: #D5086E !important;
                        }
                    }                    
                                   
                }
                
            }            
        }
        .slider__item_link {
            padding-top: 15px !important;
            width: 144px !important;
            margin-top: 15px;
            height: 35px;
            font-size: 21px !important;
            
            @media (min-width: 770px) {
                font-size: 16px;
            }

        }
        .slider__item_bg {
            right:0 !important;
            transition: all .5s;
        }
        .slider__text {
            font-size: 23px;
            margin-bottom: 10px;
            line-height: 26px;
            /* @media $small-mobile-max {
                font-size: 12px !important;
            } */
            @media screen and (max-width: 523px) {
                font-size: 12px !important;
            }
            @media (max-width: 350px) {
                font-size: 9px !important;
            }
            @media (min-width: 770px){
                font-size: 19px !important;
                line-height: 22px !important;
            }
        }
        li {
            font-size: 16px !important;    
            @media $large-mobile {
                font-size: 15px !important;                
            }
            &::before {
                @media (min-width: 770px) {
                    background-size: 25px;
                    width: 25px;
                    height: 25px;
                    margin: 5px;
                }
                @media $mobile-max {
                    background-size: 15px;
                    width: 15px;
                    height: 15px;
                    margin: 5px;
                }
            }
        }     
    
    }

    // PREVIEW
    
    .slider__item_bg {
        z-index: 1;
        right: 9px !important;
    }
    .slider__item_info {
        position:absolute;        
        top:0;
        left:0;        
        text-align:center;        
        height:100%;
        width:100%;
        .slider__item_info_text {
            padding:0 15px;
            position:absolute;
            z-index:1;
            top:50%;
            left:0;
            max-width:50%;
            text-align:left;
            transform:translateY(-50%);
        }
        .mobile {
            display: none;
            @media $mobile-max {
                display: block;
            }
        }
        .slider__item_bg_element {     
            width: 100%;    
            @media (min-width: 599px) and (max-width: 1118px) {
                left:-43px;
            }
            @media (min-width: 599px) and (max-width: 840px) {
                left: -11%;
            }
        }
        .slider__item_title {
            font-size: 18px;            
            font-weight: 700;
            line-height:1.2em;            
            color: #D5086E;                  
            display:inline-block;
            transition: all 0.2s linear;
            @media $large-mobile {
                font-size: 12px !important;
            }
        }       
        .slider__item_link{
            background: #D7006D;
            border-radius: 6px;
            font-size: 16px;
            line-height:1.2em;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 !important;
            /* display: block; */
            width: 109px;
            margin-top: 9px;
            
            @media $large-mobile {
                font-size: 14px;
            }
            &:hover {
                background: #f60d81;
            }
        }
    }
}

.Modelo-05 {
    &:nth-child(-n+2) {
        display:block;
        opacity:1;
    }
    &.slider__item--active  { 
        
        .slider__item_bg_element {
            @media (min-width: 100px) and (max-width: 999px) {
                left: 43px;
            }
        }
        .slider__item_info {
            .slider__item_title {
                font-size:20px;
            } 
            .slider__item_info_text {
                max-width:45%;
                /* @media $small-mobile-max {
                    max-width:50%;
                } */
                @media (max-width: 380px) {
                    max-width: 67% !important;
                }
                .slider__item_title {
                    font-size: 26px;                                        
                    font-weight: 700;
                    margin-bottom: 10px;
                    @media (min-width: 770px){
                        font-size: 25px !important;
                        line-height: 26px !important;
                    }
                    @media (max-width: 370px) {
                        font-size: 16px !important;
                    }
                    p {
                        strong {
                            color: #D5086E !important;
                        }
                    }                    
                                   
                }
                
            }            
        }
        .slider__item_link {
            padding-top: 15px !important;
            width: 144px !important;
            margin-top: 15px;
            height: 35px;
            font-size: 21px !important;
            
            @media (min-width: 770px) {
                font-size: 16px;
            }

        }
        .slider__item_bg {
            right:0 !important;
            transition: all .5s;
            @media $large-mobile {
                right: -160px !important;
            }
            @media $mobile-max {
                right: -24px !important;
            }
        }
        .slider__text {
            font-size: 23px;
            margin-bottom: 10px;
            line-height: 26px;
            @media $small-mobile-max {
                font-size: 12px !important;
            }
            @media (min-width: 770px){
                font-size: 19px !important;
                line-height: 22px !important;
            }
        }
        li {
            font-size: 16px !important;    
            @media $large-mobile {
                font-size: 15px !important;                
            }
            &::before {
                @media (min-width: 770px) {
                    background-size: 25px;
                    width: 25px;
                    height: 25px;
                    margin: 5px;
                }
                @media $mobile-max {
                    background-size: 15px;
                    width: 15px;
                    height: 15px;
                    margin: 5px;
                }
            }
        }     
    
    }

    // PREVIEW
    
    .slider__item_bg {
        z-index: 1;
        right: -59px !important;
        @media $large-mobile {
            right: -104px !important;
        }
    }
    .slider__item_info {
        position:absolute;        
        top:0;
        left:0;        
        text-align:center;        
        height:100%;
        width:100%;
        .slider__item_info_text {
            padding:0 15px;
            position:absolute;
            z-index:1;
            top:50%;
            left: initial !important;
            right: 0 !important;
            max-width:50%;
            text-align: right !important;
            transform:translateY(-50%);
            display: flex;
            flex-direction: column;
            align-items: end;
        }
        .slider__text  {
            @media $large-mobile {
                font-size: 12px;
            }
        }
        .mobile {
            display: none;
            @media $mobile-max {
                display: block;
            }
        }
        .slider__item_bg_element {         
            @media (min-width: 599px) and (max-width: 1118px) {
                left:-43px;
            }
            @media (min-width: 599px) and (max-width: 840px) {
                left: -11%;
            }
        }
        h2 {
            @media $large-mobile {
                font-size: 19px !important;
            }
        }
        .slider__item_title {
            font-size: 18px;            
            font-weight: 700;
            line-height:1.2em;            
            color: #D5086E;                  
            display:inline-block;
            transition: all 0.2s linear;
            @media $large-mobile {
                font-size: 20px !important;
            }
        }       
        .slider__item_link{
            background: #D7006D;
            border-radius: 6px;
            font-size: 16px;
            line-height:1.2em;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 !important;
            /* display: block; */
            width: 109px;
            margin-top: 9px;
            
            @media $large-mobile {
                font-size: 14px;
            }
            &:hover {
                background: #f60d81;
            }
        }
    }
}



</style>

<script>
export default {    
    name: "ComponentSectionsSlider",
    props: {
        component: Object,
        pageId: Number
    },
    data() {                
        return {
            api_url: process.env.VUE_APP_STRAPI_URL,
            banners: {},            
            activeSlider: 0,
            disabled:false,
            autoplay:true,
            interval:null,
            pagination: null,
            imgURL: 'play.svg',
            paused: false,
            touch: {
                startX: 0,
                endX: 0
            } 
        };        
    },
    mounted() {
        this.initCarousel();
        const element = document.querySelector('.component-slider-banners');
        element.addEventListener('touchstart', event => this.touchstart(event), { passive: true });
        element.addEventListener('touchmove', event => this.touchmove(event));
        element.addEventListener('touchend', () => this.touchend());
    },
    updated() {        
    
    },
    methods: {
        initCarousel() {    
            clearInterval(this.interval);                
            const wrapper = this.$refs.wrapper;                        
            const items = wrapper.querySelectorAll('.slider__item'); 
            this.pagination = items.length;
            items[0].classList.add('slider__item--active');
            this.activeSlider = 0;            
            this.startAutoPlay();            
        },
        touchstart(event) {
            this.touch.startX = event.touches[0].clientX;
            this.touch.endX = 0;
        },
        touchmove(event) {
            this.touch.endX = event.touches[0].clientX;
        },
        touchend() {
            if(!this.touch.endX || Math.abs(this.touch.endX - this.touch.startX) < 20)
                return;        
            if(this.touch.endX < this.touch.startX)
               this.nextSlide();
            else
               this.prevSlide();
        },
        startAutoPlay() {   
            
            this.interval = setInterval(() => {   
                if(this.autoplay) {                          
                    this.nextSlide();
                }
            }, 5000);          
        },        
        stopAutoPlay() {
            clearInterval(this.interval);
        },
        setActiveSlide(slide) {
            clearInterval(this.interval);
            const wrapper = this.$refs.wrapper;                        
            //get slider with data-index equals slide
            const items = wrapper.querySelectorAll('.slider__item');
            const index = Array.from(items).findIndex(item => item.classList.contains('slider__item--active'));
            items[index].classList.remove('slider__item--active');
            const sliderIndex = wrapper.querySelector(`[data-index="${slide}"]`);
            sliderIndex.classList.add('slider__item--active');
            
            //remove class from current item
            
            
            //take active slider to first element                    
            wrapper.insertBefore(sliderIndex, wrapper.firstChild);
            this.rearrangeSlides(sliderIndex);  
            //set current item with active
            let dots = this.$refs.dots
            const dot = dots.querySelector(`[data-banner="${slide}"]`);            
            const dotsItems = dots.querySelectorAll('li');
            //remove class active from dotsItems
            dotsItems.forEach(item => item.classList.remove('active'));            
            dot.classList.add('active');
            
            this.startAutoPlay();        
        },
        rearrangeSlides(activeSlider ) {
            const wrapper = this.$refs.wrapper;                        
            const items = wrapper.querySelectorAll('.slider__item'); 
            //getActive slider
            
            //get index of active slider
            const index = activeSlider.dataset.index;
            let currentTime = 0;
            for(let i = index; i <= this.pagination; i++) {                
                currentTime++;
                //get slider with data-index equals i
                const sliderIndex = wrapper.querySelector(`[data-index="${i}"]`);
                //remove sliderIndex from wrapper
                sliderIndex.style.removeProperty('display');
                wrapper.removeChild(sliderIndex);
                //move sliderIndex to the end of wrapper
                wrapper.appendChild(sliderIndex);
                //set transition time
                
            }
            if(this.pagination - currentTime > 0) {
                for(let i = 1; i <= this.pagination-currentTime; i++) {
                    const sliderIndex = wrapper.querySelector(`[data-index="${i}"]`);
                    sliderIndex.style.removeProperty('display');
                    wrapper.removeChild(sliderIndex);
                    //move sliderIndex to the end of wrapper
                    wrapper.appendChild(sliderIndex);
                    //set transition time
                }
            }      
        },        
        nextSlide() {
            this.disabled=true;          
            const wrapper = this.$refs.wrapper;     
            if(wrapper) {
                const items = wrapper.querySelectorAll('.slider__item');
                const dots = this.$refs.dots.querySelectorAll('li');
                
                //pagination
                let pages = Array.from(dots).findIndex(dot => dot.classList.contains('active'))
                if (dots[pages]) dots[pages].classList.remove('active')           
                let nextDot = dots[pages + 1]
                if (nextDot)  nextDot.classList.add('active')
                if (pages + 1 >= items.length)  dots[0].classList.add('active')

                //identify index by .slider__item--active
                const index = Array.from(items).findIndex(item => item.classList.contains('slider__item--active'));

                //remove class from current item
                items[index].classList.remove('slider__item--active');
                items[index].style.opacity = 0;
                items[index].style.width = '0';         
                //get second slide                        
                const next = items[index + 1];
                next.classList.add('slider__item--active');
                //move second slide to first position
                if(index + 2 <= items.length) {                                
                    items[index +2].style.display = 'block';                
                }
                setTimeout(() => {
                    wrapper.prepend(next);
                    wrapper.append(items[index]);
                    items[index].style = '';
                    this.disabled=false;          
                }, 200);
            }
            //add class to new first slide
        },
        prevSlide() {
            clearInterval(this.interval);
            this.disabled = true;
            const wrapper = this.$refs.wrapper;
            const items = wrapper.querySelectorAll('.slider__item');
            let dots = this.$refs.dots

            let pages = Array.from(dots).findIndex(dot => dot.classList.contains('active'));         
            if (dots[pages]) dots[pages].classList.remove('active');
            let prevDot = dots[pages - 1]
            if (prevDot) prevDot.classList.add('active');            
            if (pages == 0) {
                pages = dots.length - 1;   
                dots[pages].classList.add('active');
            }          

            //identify index by .slider__item--active
            const index = Array.from(items).findIndex(item => item.classList.contains('slider__item--active'));
            //remove class from current item
            items[index].classList.remove('slider__item--active');
            //get last Slide
            const last = items[items.length - 1];
            //hide second item
            items[index +1].style.display = 'none';
            items[index].style.marginLeft = 'auto';
            last.style.marginRight = 'auto';
            setTimeout(() => {
                //move last slide to first position
                last.classList.add('slider__item--active');
                this.disabled=false;
                wrapper.prepend(last);
                last.style.transform= 'translateX(0)';
                items[index +1].style.display = '';
                last.style = '';
            }, 100);         
        },
        pauseSlide() {            
            clearInterval(this.interval);
            this.autoplay = !this.autoplay;
            const dotInner = document.querySelector('.slider_doots .active .dots-inner');
            dotInner.style.animationPlayState = 'paused';
            if (!this.autoplay) {
                this.imgURL = 'stop.svg'
            } else {
                this.startAutoPlay();           
                this.imgURL = 'play.svg'
                const dots = document.querySelectorAll('.slider_doots .dots-inner');
                dots.forEach(dot => {
                    dot.style.animationPlayState = 'running';
                });
            }
        },
        isExternal(url) {
            return /^https?:\/\//i.test(url);
        }
    }
}
    
</script>


