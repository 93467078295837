<template>

  <div class="shares pt-46" v-if="url.path.includes('/noticias')">
    <br>
    <br>
    <h2 class="noticias-title">Compartilhar</h2>
    <ul data-v-78d0f51e="" class="social-links_ul">


      <li data-v-78d0f51e="" class="social-links_li">
        <a data-v-78d0f51e="" target="_blank" :href="`https://api.whatsapp.com/send?text=https://www.amilone.com.br${url.path}?utm_source=whatsapp&utm_medium=compartilhamento-blog&utm_campaign=compartilhamento-site`">
          <img data-v-78d0f51e="" src="https://admin.amilone.com.br/uploads/whatsapp_24_32b327b142_074af4b8f0.png" alt="Whatsapp.svg">
        </a></li>
      <li data-v-78d0f51e="" class="social-links_li">
        <a data-v-78d0f51e="" target="_blank" :href="`https://twitter.com/intent/tweet?text=AmilOne&url=https://www.amilone.com.br${url.path}?utm_source=twitter&utm_medium=compartilhamento-blog&utm_campaign=compartilhamento-site`">
          <img data-v-78d0f51e="" src="https://admin.amilone.com.br/uploads/twitter_24_4a1cc0c8c2_1e082a6ad5.png" alt="twitter.svg">
        </a>
      </li>
<!--      <li data-v-78d0f51e="" class="social-links_li">-->
<!--        <a data-v-78d0f51e="" target="_blank" :href="`https://www.facebook.com/sharer/sharer.php?u=https://www.amilone.com.br${url.path}?utm_source=facebook&utm_medium=compartilhamento-blog&utm_campaign=compartilhamento-site`">-->
<!--          <img data-v-78d0f51e="" src="https://admin.amilone.com.br/uploads/facebook_24_815d5de652_3432642e44.png" alt="facebook.svg">-->
<!--        </a></li>-->
<!--      <li data-v-78d0f51e="" class="social-links_li">-->
<!--        <a data-v-78d0f51e="" target="_blank" :href="`https://www.linkedin.com/sharing/share-offsite/?url=https://www.amilone.com.br${url.path}?utm_source=linkedin&utm_medium=compartilhamento-blog&utm_campaign=compartilhamento-site`">-->
<!--          <img data-v-78d0f51e="" src="https://admin.amilone.com.br/uploads/linkedin_3_24_b532ab65dd_deabcac2c5.png" alt="[AMIL ONE] SITE - ÍCONES Linkedin-02.svg">-->
<!--        </a>-->
<!--      </li>-->
      <li data-v-78d0f51e="" class="social-links_li">
        <a data-v-78d0f51e="" target="_blank" >
          <img data-v-78d0f51e=""
               @click="copy"
                src="https://admin.amilone.com.br/uploads/link_24_9287af4d9d_174bb9d470.png" alt="[AMIL ONE] SITE - ÍCONES link-01.svg" >
        </a>
      </li>
    </ul>
  </div>
  <div class="input-share" v-show="showDiv">
    <input type="text" name="" id="input-share-field" :value="`https://www.amilone.com.br${this.url.path}?utm_source=copy&utm_medium=compartilhamento-blog&utm_campaign=compartilhamento-site`">
    <button @click="copiarLink()">Copiar</button>
    <small v-show="showMsg">Copiado !</small>
  </div>


</template>

<script>
import {useRoute} from "vue-router";


export default {
  name: "ComponentSectionsShareSocialMedia",
  props: {
    component: Object
  },
  data() {
    return {
      api_url: process.env.VUE_APP_STRAPI_URL,
      url: useRoute(),
      showMsg:false,
      showDiv:false
    };
  },
  methods: {
    copy() {
      navigator.clipboard.writeText('https://www.amilone.com.br'+this.url.path+'?utm_source=copy&utm_medium=compartilhamento-blog&utm_campaign=compartilhamento-site')
      this.showDiv = true
    },
    copiarLink() {
      navigator.clipboard.writeText('https://www.amilone.com.br'+this.url.path+'?utm_source=copy&utm_medium=compartilhamento-blog&utm_campaign=compartilhamento-site')
      this.showMsg = true;
    }
  }
}
</script>


<style scoped>
  .social-links_ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

  }

  .pt-46{
    padding-top: 46px;

  }

  @media (max-width: 991px) {
    .pt-46{
      padding-top: 0;
    }
  }

  .social-links_li {
    margin: 0 10px 10px 0;
    max-width: 100%;
    width: 34px;
  }

  .input-share input {
    border-radius: 20px;
    margin-right: 5px;
    border: 1px solid;
  }
  .input-share button {
    border-radius: 20px;
    background-color: #D5086E;
    color: white;
    border: none;
    width: 60px;
    height: 28px;
    margin-right: 5px;
  }
  .input-share small {
    color: green;
  }
</style>