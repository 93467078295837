<template>
    <div class="component-page-selector" v-if="component"
        :style="{color: component.color == '#D5086E' ? '#3B3B3A' : (component.color ? component.color : '#fff') }">  
        <img class="component-page-selector-image" v-if="component.background_image && component.background_image.data" :src="api_url + component.background_image.data.attributes.url" alt="">

        <div class="container">
            <ul class="page-selector-breadcrumb" v-if="component.breadcrumb"> 
                <li><a href="/">Home </a> </li>            
                <li> <span class="separator"> - </span> </li>
                <li v-if="parent_page.length > 0">
                    <a :href="parent_page_url" v-html="parent_page_title"></a>
                </li>            
                <li  v-if="parent_page.length > 0"> <span class="separator"> - </span> </li>
                <li class="bold" :style="{ color: component.color ? component.color : '#fff' }">{{page_title}}</li>                       
            </ul>
            <div class="component-page-selector__title">
                <h2 class="title-page-selector" :style="{ color: component.color ? component.color : '#fff' }">{{component.title}}</h2>
                <div class="component-page-selector__description" v-html="component.description" :style="{color: component.color == '#D5086E' ? '#3B3B3A' : (component.color ? component.color : '#fff') }"></div>
                <div class="page-list">
                    <swiper
                        :breakpoints="swiperOptions.breakpoints" :centeredSlides="false" :parameters="swiperOptions"  v-if="pages.length > 0" class="swiper-selector" @slideChange="onSlideChange" @swiper="swiperInit" ref="swiper">
                        <swiper-slide v-for="page in pages" :key="page.id" ref="swiperSlide">
                        <a :href="`/${page.attributes.parent_page.data.attributes.slug}/${page.attributes.slug}#scroll`" class="btn-links-pages" :class="pageId == page.id ? 'active':''">
                            <div class="icon-svg" v-if="page.attributes.icon_svg" v-html="page.attributes.icon_svg"></div>
                            <img v-if="!page.attributes.icon_svg && page.attributes.icon.data" :src="api_url + page.attributes.icon.data.attributes.url" alt="">
                            {{page.attributes.title}}
                        </a>
                        </swiper-slide>
                    </swiper>

                    
                </div>
                
                <div class="navigation-swiper" :class="{ show : showArrows}">
                    <button @click="prevSwiper('.swiper-selector')" class="prev-swiper-nav" :disabled="disabledPrev">Prev</button>
                    <button @click="nextSwiper('.swiper-selector')" class="next-swiper-nav" :disabled="disabledNext">Next</button>                    
                </div>
            </div>

        </div> 
    </div>
</template>
<style lang="styl"> 
.color-teste {
    color: red;
}
.component-page-selector {
    position: relative;
    @media (max-width: 992px) {
        padding-bottom: 20px;
    }
    
    .swiper-wrapper {
        padding:30px 0;
        margin-top:-30px;

    }

    .prev-swiper-nav {
        background: url(../../images/prev.svg);
        background-repeat: no-repeat;            
        width:30px;
        height:30px;
        border:0;
        text-indent:-9999px;
        margin:0 10px;
        &:disabled {
            opacity:0.35;
        }
    }

    .next-swiper-nav {
        background: url(../../images/next.svg);
        background-repeat: no-repeat;                                
        border:0;
        width:30px;
        height:30px;
        text-indent:-9999px;
        margin:0 10px;        
        &:disabled {
            opacity:0.35;
        }
                
    }
    .navigation-swiper {
        width:100%;
        text-align:center;        
        display:none;
        margin-bottom:30px;
        &.show {
            display:block;
        }
        
    }
}
.component-page-selector-image {
    position: absolute;    
    width:100%;
    height:auto;
    bottom: 100px;
    
    @media $large-mobile {
        width: 100%;
        height: 100%;
        object-fit: cover;
        bottom: 140px;
        top: auto;
        
    }
}
.page-selector-breadcrumb {
    display: flex;
    gap: 10px;
    padding-top: 32px;
    font-size: 18px;
    line-height: 19px;    
    a {
        &:hover {
            color: #D5086E;
        } 
    } 
    li {
        margin-bottom:0;
    }
    
}
.icon-svg {
    display: flex;
    align-items: center;
    justify-content: center;
}
.swiper-button-prev::before {
    background: url(../../images/prev.svg);
    background-repeat: no-repeat;
    content:"";
    display: inline-block;
    height: 50px;
    position: absolute;
    bottom: -16px;
    width: 32px;
    left: -1px;
}

.swiper-button-next::before {
    background: url(../../images/next.svg);
    background-repeat: no-repeat;
    content:"";
    display: inline-block;
    height: 32px;
    position: absolute;
    bottom: 4px;
    width: 50px;    
    right: -19px;
}
.swiper-button-next, .swiper-button-prev {
    color: #D5086E;
    display: block;
    &::after {
        display: none;
    }
} 
.container {
    position: relative;
}

.bold {
    font-weight: bold;
}

.page-list {    
    justify-content:center;
    gap:40px;
    padding:50px;
   
    

    .swiper-button-next, .swiper-button-prev {
        color: #D5086E !important;
        display: block;
        @media $large-mobile {
            display: block;
        }
    }   
    
    

}
.component-page-selector__description {
    padding:30px;
}
.component-page-selector__title {
    padding: 89px 0 0 0;
    text-align:center;  
    @media $large-mobile {
        padding: 0;
    }
}
.title-page-selector {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 42px;
    /* identical to box height */
    @media $large-mobile {
        padding-top: 51px;
    }
    

}
.btn-links-pages {
    
    display:flex;
    flex-direction:column;
    background: #fff;
    height: 161px;
    width:100%;
    max-width:100%;   
    border-radius: 16px;
    padding: 24px 12px 24px 12px;
    justify-content:center;
    align-items:center;   
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);                     
    text-align:center;
    gap:30px;
    row-gap:10px;
    cursor:pointer;
    color: #3B3B3A;
    &:hover,
    &.active {
        background:#D5086E;
        color:#fff;
        .icon-svg {
            svg {
                g {
                    rect {
                        fill:#fff;
                    }
                }
                path {
                    stroke:#fff;
                }
                
            }
        }
        img {
            filter: brightness(100);
        }
    }
    
}
</style>
<script>
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);
import 'swiper/css/navigation'
import 'swiper/css';
export default {
    name: "ComponentSectionsFAQ",    
    props: {
        component: Object,
        pageId: Number
    },
    components: {
        Swiper,
        SwiperSlide
    },
    data() {     
           
        return {            
            api_url: process.env.VUE_APP_STRAPI_URL,
            disabledNext: false,
            swiper: {},
            disabledPrev: true,
            showArrows: false,
            categories: [],
            duvidas: [],
            featured: {},
            parent_page: 0,
            parent_page_url: '',
            parent_page_title: '',
            page_title: '',
            pages: [],
            swiperOptions: {        
                breakpoints: {       
                    320: {       
                        slidesPerView: 2,
                        spaceBetween: 10,    
                    },
                    400: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    600: {       
                        slidesPerView: 3,       
                        spaceBetween: 10,  
                    },
                    720: {       
                        slidesPerView: 4,       
                        spaceBetween: 10,   
                    },
                    840: {       
                        slidesPerView: 5,       
                        spaceBetween: 10,   
                    },
                    1100: {
                        slidesPerView: this.pages && this.pages.length < 5 ? this.pages.length : 5,
                        spaceBetween: 20,
                    },
                    1440: {
                        slidesPerView: this.pages && this.pages.length < 7 ? this.pages.length : 7,
                        spaceBetween: 20,
                    }
                },
        
            }

        };
    },    
    async mounted() {        
        const data = await this.setup(); 
        this.getPageLink();               
    },

    
    methods: {
        nextSwiper(swiper) {
            const swiperEl = document.querySelector(swiper);
            swiperEl.swiper.slideNext();            
        },
        prevSwiper(swiper) {
            const swiperEl = document.querySelector(swiper);
            swiperEl.swiper.slidePrev();            
        },
        onSlideChange(swiper) {
         
            if(swiper.isBeginning) {
                this.disabledPrev = true;
            } else {
                this.disabledPrev = false;
            }
            if(swiper.isEnd) {
                this.disabledNext = true;
            } else {
                this.disabledNext = false;
            }                    
        },
        checkArrows() {                        
            const swiper = this.swiper;
            const swiperEl = swiper;                        
            const swiperSlide = swiper.slides;
            
           
            const swiperElWidth = swiperEl.$el[0].clientWidth;
            
            
            // get swiper slide width
            const swiperSlideWidth = swiperSlide[0].clientWidth;            
            const totalWidth = this.pages.length * swiperSlideWidth;
            
            if(totalWidth > swiperElWidth) {
                this.showArrows = true;
            } else {
                this.showArrows = false;
            }
        },
        getPageLink() {
           console.log('aqui', this.pages)
        },
        async setup() {
            
            const pageId = this.$props.pageId;                                   
            this.parent_page = pageId;
            const pages = await axios.get(`${this.strapi_api_url()}/pages/${pageId}`).then(response => {
                this.page_title = response.data.data.attributes.title;           
                if (response.data.data.attributes.publishedAt == null) window.location.href = '/404';   
                if(response.data.data.attributes.parent_page.data) {                    
                    this.parent_page = response.data.data.attributes.parent_page.data.id;
                    this.parent_page_url = response.data.data.attributes.parent_page.data.attributes.slug;
                    this.parent_page_title = response.data.data.attributes.parent_page.data.attributes.title;
                } 
            });

            this.getChildPages().then(response => {                
                this.pages = response;  
                this.swiperOptions.breakpoints[1440].slidesPerView = this.pages.length < 7 ? this.pages.length : 7;
            });            
        },  
        async getChildPages() {            
            const pages = axios.get(`${this.strapi_api_url()}/pages?[filters][parent_page]=${this.parent_page}&sort[0]=rank`).then(response => {       
                return response.data.data;
            });
            return pages;
        },
        strapi_api_url: function () {
            return process.env.VUE_APP_STRAPI_API_URL;
        },       
        searchDuvidas: function() {                   
            
        },        
        swiperInit(swiper) {
            this.swiper = swiper
            this.checkArrows();
            window.addEventListener('resize', this.checkArrows); 
        }
    }
}
</script>


