<template>
  <div class="">
    <div v-if="component">
      <section class="information">
        <div class="center-content">
          <div class="title-section">
            <h2 class="t-45 t-lh-56 t-darkBlue t-sb mb-4">
              {{ component.title }}
            </h2>
          </div>
          <div class="grid-company-information">
            <div class="card" v-for="card in component.card_with_imagem" :key="card">
              <div class="card-content flexed keep-flex aic-center">
                <picture>
                  <source v-if="card.image_mobile.data.attributes" :srcset="api_url + card.image_mobile.data.attributes.url" media="(max-width:599px)">
                  <img :src="api_url + card.image_desktop.data.attributes.url" alt="" class="desk-version" />
                </picture>
                <div class="flexed keep-flex column jfc-between">
                  <h3 class="t-19 t-lh-18 t-darkBlue t-sb">
                    {{ card.title }}
                  </h3>
                  <p class="t-16 t-lh-18">
                    {{ card.text }}
                  </p>
                  <a class="btn-know-more t-16 t-lh-13 t-darkBlue t-lig" :href="card.button_url">{{ card.button_text }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style lang="styl">
  .information{
    padding: 100px 0px 100px;   
    .title-section{
      text-align: center;
        h2{
            color: $darkBlue;
            font-size: 2.815rem;
            font-weight: 600;
        }
    }

    .grid-company-information{
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 25px;

        .card-content{
            align-items: stretch;
            max-width: 500px;
            height: 228px;
            display: flex;
            
            img{
                border-radius: 18px;
                z-index: 10;
                max-width: 500px;
                border-radius: 13px;
                border: 5px solid #fff;
            }

            .flexed{
                border: 4px solid $darkBlue;
                border-radius: 10px
                padding: 16px 16px 30px 30px;
                margin-left: -10px;
                z-index: 1;

              h3{
                color: $darkBlue;
                font-size: 25px;
                line-height: 18px;
              }

              p{
                font-size: 16px;
                line-height: 18px;
              }

              a{
                color: $darkBlue;
                font-height: 300;
                font-size:16px;
                line-height:13px;
                text-transform: uppercase;
              }

                @media $mobile-max{
                    margin-top: -10px;
                    max-width: 255px;
                    margin-left: auto;
                    margin-right: auto;
                    padding: 16px 16px 30px 16px;

                    h3{
                        margin-top: 20px;
                        margin-bottom: 20px;
                        text-align: center;
                        
                        br{
                            display: none;
                        }
                    }

                    p{
                        margin-bottom: 30px;
                        text-align: center;
                    }
                }
            }

            .btn-know-more {
                border: solid 1px $darkBlue;
                border-radius: 30px;
                padding: 16px 0px;
                text-align: center;
                transition: .6s;
            }

            .btn-know-more:hover {
                background-color:$darkBlue;
                color: $white;
            }

            @media $mobile-max{
                flex-direction: column;
                height: 536px;
                max-width: 255px;         
            }
        }

        @media $mobile-max{
            flex-direction: column;
            .slick-list {
                padding:0 12% 0 0 !important;
                @media (max-width: $minSmallerContextPX){
                    padding:0 0 0 0 !important;
                }
            }
        }
    }

    .arrow-company{
        align-items: center;
        display: flex;
        justify-content: space-between;
        gap: 19px;
        margin-top: 20px;
        margin-left: auto;
        max-width: 19px;
        transform: translateX(-40px);
        width: 100%;

        .slick-arrow{
            cursor: pointer;
            path{
                fill: $blue;
            }
        }

        .slick-arrow.slick-disabled{
            path{
                fill: #989898;
            }
        }

        @media $large-mobile{
            margin-left: auto;
            margin-right: auto;
            transform: translateX(0px);
        }
    }

     .desk-version{
        @media $mobile-max{
          width: 100%;
          object-fit: cover;
        }
    }

    @media $large-mobile{
        .title-section{
            text-align: center;

            h2{
                margin-bottom:40px;
            }

            @media $mobile-max {
                .mb-4{
                    margin-bottom: 40px !important;
                }
            }
        }

    }

    @media $mobile-max{
        padding: 25px 0px 40px;
    }
}
</style>
<script>
export default {
  name: "ComponentSectionsCardsWithImagem",
  props: {
    component: Object,
  },
  data() {
    return {
      api_url: process.env.VUE_APP_STRAPI_URL,
    };
  },
};
</script>
