<template>
    <section>       
        <div v-if="component" class="Accordion"
        :class="{ dark_background: component.dark_background }">
            <div class="center-content">
                <div class="title-section">
                    <h2
                    v-html="component.title">
                    </h2>
                    <div v-html="component.description" class="description"></div>
                </div>   
                <div class="accordion_list">
                     <Accordion v-for="component in component.Accordion" :key="component.id" :component="component"  />
                </div>
                <div class="pre-link-accordion">
                    <a class="link-accordion"
                        v-if="component.button_text" 
                        :href="component.button_link">
                        {{ component.button_text }}
                    </a>
                </div>
            </div>
        </div>
    </section>    
</template>

<style lang="styl">

.Accordion {
    padding: 50px 30px;
   .title-section {
    h2 {
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        color: #3B3B3A;
    }
   }
   .accordion_list {
        margin: 20PX 0;
        border-radius: 16px;
        color: #36363A;
   }
   &.dark_background {
        .description p {
            padding:40px 0;
            color:#fff;
        }
        .pre-link-accordion {
            text-align:center;
        }
        .link-accordion {
            display:inline-block;
            color:#fff;
            padding:20px;                        
            height: 55px;
            text-align:center;
            
            /* Amil One / Burgundy */
            background: #D5086E;
            border-radius: 200px;
            margin:40px auto;
        }
    }   
}

</style>
<script>
import Accordion from '../Accordion.vue';
export default {
    name: "ComponentSectionsAccordion",
    props: {
        component: Object,
    },
    components: {
        Accordion
    }, 
    data() {        
        return {
            api_url: process.env.VUE_APP_STRAPI_URL,     
            open: false                   
        };
             
    },
}
</script>