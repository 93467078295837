<template>
    <div>
        <div v-if="component">             
            <section class="hopitals">
                <div class="unit">
                    <div class="center-content">
                        <div class="flexed keep-flex aic-center jfc-between mb-4 row">
                            <!-- <img src="<?php echo $url . 'images/hospital-butanta.png'?>"> -->
                            <div class="title-section">
                                <h2 class="t-45 t-lh-56 t-darkBlue t-sb mb-2">
                                    {{component.title}}
                                </h2>
                                <div class="flexed keep-flex aic-center mb-5">
                                    <!-- <img class="pdr-2" src="<?php echo $url . 'images/icon-cellphone.svg'?>" aria-hidden="true"> -->
                                    <p class="t-18 t-lh-25">
                                      {{component}}
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>    
</template> 
<style lang="styl">
    .hopitals{
        padding-bottom: 90px;

        .unit{
            padding: 60px 0px;

            @media $large-mobile{
                padding: 45px 0px;
                
                .btn-more{
                    margin: auto;
                }
            }
        }

        .unit:nth-child(2n){
            .row{
                flex-direction: row-reverse;
                    
                .title-section{
                    margin-right: auto;
                    margin-left: initial;

                     @media $large-mobile{
                        margin: auto;
                    }
                }

                @media $large-mobile{
                    flex-direction: column;
                }
            }
        }

        .flexed{
            img{
                @media $large-mobile{   
                    margin: 0px auto 30px;
                    max-width: 390px;
                    position: initial;
                }

                @media $mobile-max{
                    max-width: 100%;
                }
            }

            @media $large-mobile{
                flex-direction: column;
            }
        }

        .title-section{
            margin-left: auto;
            max-width: 450px;

            p{
                max-width: 450px;

                   @media $large-mobile{
                        max-width: 500px;
                        text-align: center;
                    }
            }

            ul{
                li:before{
                    background-color: $blue;
                    border-radius: 50%;
                    content:"";
                    display: inline-flex;
                    height: 11px;
                    margin-right: 7px;
                    width 11px;
                }

                @media $large-mobile{
                    text-align: left;
                    margin:auto;
                    max-width: 145px;
                }
            }

            @media $large-mobile{
                margin: auto;
                max-width: 500px;
                text-align: center;

                .flexed{
                    margin-bottom: 30px;
                }

                h2{
                    margin-bottom: 30px;
                }
            }
        }

        .title-section:nth-child(2){
            // margin-right: auto;
        }

        .desk-version{
            @media $mobile-max{
                display: none;
            }
        }

        .mobile-version{
            display: none;

            @media $mobile-max{
                display: block
            }
        }

        @media $large-mobile{
            padding-bottom: 30px;

            .mb-4{
                margin-bottom: 50px;
            }
        }

         @media $mobile-max{
            .t-45{
                font-size: 30px;
            }

            .t-lh-56{
                line-height: 40px;
            }
        }
    }
</style>
<script>
export default {
    name: "ComponentSectionsImgWithTextAndLocalization",
    props: {
        component: Object,
    },
    data() {        
        return {
             api_url: process.env.VUE_APP_STRAPI_URL,
        };
    },
}
</script> 
