<template>
    <div v-if="component" class="text-widget">
        <h3 v-if="component.title">{{ component.title }}</h3>
        <ul class="social-links__ul">
            <li v-for="item in component.links" :key="item.id" class="social-links__li">
                <a target="_blank" :href="item.url"><img v-if="item.icon.data" :src="this.api_url + item.icon.data.attributes.url" 
                :alt="item.icon.data.attributes.alternativeText"></a>                
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "ComponentPartsImage",
    props: {
        component: Object,
        wrapperClasses: {
            type: String,
            default: "",
        },
    },
    data() {        
        return {
            api_url: process.env.VUE_APP_STRAPI_URL,
        };
    },
}
</script>
<style lang="styl" scoped>
.text-widget{
    @media $large-mobile {
        text-align: left !important;
        padding: 20px;
    } 
}

h3 {
    color:#fff;
    line-height:1em;
    padding-bottom:0;
    margin-bottom:20px;
    &:after {
        content:'';
        display:block;
        background:#D5086E;
        width: 18px;
        border-radius: 30px;
        height: 5px;                
    }
}
    .social-links__ul{
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
    }
    .social-links__li{
        margin: 0 10px 10px 0;
        max-width: 100%;
        width: 34px;
        img {
            width: auto;
            height: auto;
        }             
    }
</style>