<template>
    <div class="component-page-404" v-if="component" :style="{color: component.color == '#D5086E' ? '#3B3B3A' : (component.color ? component.color : '#fff') }">  
        <img class="component-page-404-image" v-if="component.background_image && component.background_image.data" :src="api_url + component.background_image.data.attributes.url" alt="">
        <div class="container">
            <div class="component-page-404__title">
                <h2 class="title-page-404" :style="{ color: component.color ? component.color : '#fff' }">{{component.title}}</h2>
                <p class="description-page-404" v-if="component.erro" v-html="component.erro"></p>
            </div>
        </div> 
    </div>
</template>
<style lang="styl"> 
.component-page-404 {
    background: #E5E5E5;
    position: relative;
    padding-bottom: 122px;
    @media $large-mobile {
        padding-bottom: 136px;
    }
    @media $mobile-max {
        padding-bottom: 192px;
    }
}
.component-page-404-image {
    position: absolute;    
    width:100%;
    height:auto;
    bottom: 96px;    
    @media $large-mobile {
        width: 100%;
        height: 100%;
        object-fit: cover;
        bottom: 140px;
        top: auto;        
    }
    @media $mobile-max {
        bottom: 92px;
    }
}
.container {
    position: relative;
}

.component-page-404__title {
    padding: 89px 0 0 0;
    text-align:center;  
    @media $large-mobile {
        padding: 0;
    }
}

.description-page-404 {
    font-weight: 800;
    font-size: 250px;
    line-height: 260px;
    text-align: center;
    color: #595758;  
    @media $mobile-max {
        font-weight: 800;
        font-size: 135px;
        line-height: 35px;
        margin-top: 62px;
    }
}

.title-page-404 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 42px;
    /* identical to box height */
    @media $large-mobile {
        padding-top: 51px;
    }   
    @media $mobile-max {
        font-weight: 500;
        font-size: 28px;
        line-height: 35px;
    }

}
</style>
<script>
export default {
    name: "ComponentSectionsFAQ",    
    props: {
        component: Object,
        pageId: Number
    },
    data() {           
        return {            
            api_url: process.env.VUE_APP_STRAPI_URL,

        };
    },  
}
</script>


