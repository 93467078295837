<template>    
    
    <div class="balloon">
        <div class="balloon-question">
            <div class="icon-balloon">
                <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="30.5" cy="30" r="30" fill="white" fill-opacity="0.2"/>
                    <circle opacity="0.2" cx="30.5" cy="30" r="20" fill="white"/>
                    <path d="M25.5 26.1996C25.5005 23.5552 27.4089 21.3322 29.9351 21.0336C32.4613 20.735 34.8006 22.4559 35.3716 25.0328C35.9425 27.6097 34.5632 30.222 32.1662 31.1035C31.1669 31.4709 30.4991 32.4539 30.4996 33.5562V34" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M30.25 39C29.8358 39 29.5 39.3358 29.5 39.75C29.5 40.1642 29.8358 40.5 30.25 40.5C30.6642 40.5 31 40.1642 31 39.75C31 39.3358 30.6642 39 30.25 39V39" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <h2 class="title-balloon">{{component.title}}</h2>
        </div>
        <div class="balloon-answer" v-html="component.description"></div>        
    </div>
</template>
<style lang="styl">
    .balloon-question {
        display:flex;
        background:#D5086E;        
        align-items:center;
        align-content:center;
        padding: 24px 16px;
        gap: 12px;
        border-radius:24px;
        position:relative;
        margin:40px 0;
        color:#FFF;
        @media $mobile-max { 
            padding: 13px 15px;
            margin: 40px 0;
        }
        &:after {
            /* arrow bottom */
            content:'';
            position:absolute;
            bottom:-12px;
            left:40px;
            width:0;
            height:0;
            border-left:12px solid transparent;
            border-right:12px solid transparent;
            border-top:12px solid #D5086E;


        }
        .icon-balloon {
            flex-shrink:0;
        }
    }
    .title-balloon {      
        font-weight: 700;
        font-size: 22px;
        line-height: 150%;                
        color: #FFFFFF;
        @media $mobile-max {
            font-size: 18px;
            line-height: 27px;
        }

    }
    .balloon-answer {
        font-family: 'Amil Typeface';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        @media $mobile-max {
            font-size: 16px;            
        }
    }
    .balloon-date {
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
    }
</style>
<script>

export default {
  name: "ComponentCard",
  props: {
    component: Object,
  },
  methods: {
    formatDate(date) {
      const dateObj = new Date(date);
        return dateObj.toLocaleDateString("pt-BR", {
            year: "numeric",
            month: "long",
            day: "numeric",                  
        }) + ' - ' + dateObj.toLocaleTimeString("pt-BR", {
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        });
    },
  },
  data() {
    return {
      api_url: process.env.VUE_APP_STRAPI_URL,
    };
  },
};
</script>