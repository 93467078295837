<template>
    <div v-if="component" class="contact-form">
        <div class="center-content">
            <form class="contact-form__form" @submit.prevent="validateFields()" ref="form">
                <h3 class="form-title" v-if="component.title"> {{component.title}}</h3>
                <div class="form-intro" v-if="component.description" v-html="component.description"></div>              
                <template v-for="fields in component.Fields" v-bind:key="fields.id">
                    <div class="form-group" :class="fields.classlist">                    
                        <input v-if="fields.type === 'text'" type="text" :id="fields.name" :name="fields.name" :placeholder="fields.placeholder" :value="fields.value"   :required="fields.required" v-bind:key="fields.id"> 
                        <textarea v-if="fields.type === 'textarea'" :id="fields.name" :name="fields.name" :placeholder="fields.placeholder" :value="fields.value"   :required="fields.required" :class="fields.classlist" v-bind:key="fields.id"></textarea>
                        <input v-if="fields.type === 'email'" type="email" :id="fields.name" :name="fields.name" :placeholder="fields.placeholder" :value="fields.value"  :required="fields.required"  :class="fields.classlist" v-bind:key="fields.id">
                        <input type="hidden" name="idGA_lead" id="idGA_lead" />
                    </div>
                </template>                
                <button class="btn-form">Enviar</button>
            </form>
            <figure v-if="component.image.data" class="contact-form__image">
                <img :src="this.api_url + component.image.data.attributes.url" :alt="component.image.data.attributes.alternativeText">
            </figure> 
            <div class="contact-for__text" v-if="component.text" v-html="component.text"></div>                    
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { useToast } from "vue-toastification";
export default {
    name: "ComponentPartsForm",
    props: {
        component: Object,
        wrapperClasses: {
            type: String,
            default: "",
        },
    },  
    methods: {

        validateFields() {
          const fields = this.component.Fields;                
          let validate = true;
          fields.forEach(field => {            
            if (this.$refs.form[field.name].required && this.$refs.form[field.name].value.length < 3) {
                validate = false;
                
                this.$refs.form[field.name].parentElement.classList.add("error");
            }
          })
          if(validate) {
            this.sendEmail();
          } else {
            const toast = useToast(); 
            toast.error("Confira os campos preenchidos!", {                    
                    position: "top-center",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });

          }

        },
        sendEmail() {                                              
            const fields = this.component.Fields;                
            let $html =  '<p> Mensagem enviada pelo site<br>'
            fields.forEach(field => {
                $html += `<strong>${field.placeholder ? field.placeholder : field.name}:</strong> ${this.$refs.form[field.name].value}<br>`
            });            
            const api_url = process.env.VUE_APP_STRAPI_API_URL;
            // get form data            
            axios.post(`${api_url}/email/`, {
                to: 'andre@prumotecnologia.com.br',
                from: 'andre@prumotecnologia.com.br',
                subject: 'Formulario de contato enviado pelo site',
                html: $html,
            })
            .then(function () {
                                
                const toast = useToast(); 
                
                const idGA_lead = document.querySelector('#idGA_lead').value;
                window.dataLayer.push({
                    'event': 'id_lead',
                    'event': 'send',
                    'idGA_lead': idGA_lead
                });
               
                toast.success("Formulário enviado com sucesso!", {
                    toastClassName: "toast-amil",
                    position: "top-center",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
                

            })
            .catch(function (error) {
                console.log(error);
            });                
        },
        getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
                }
            }
            return "";
            },

        getUTMzz() {
            if (getCookie("__utmzz")) {
                var utms = getCookie("__utmzz");
                var utmsA = utms.split('|');
                var utmsFinal = [];
                utmsA.forEach(function (el) {
                var splitedEl = el.split('=');
                utmsFinal[splitedEl[0]] = splitedEl[1];
                })
            }
        },
        create_UUID() {
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        getUUID() {
            let uuid = localStorage.getItem('uuid');
            if (!uuid) {
                uuid = create_UUID();
                localStorage.setItem('uuid', uuid);
            }
            return uuid;
        },
    },
    data() {
        
        return {             
            form: '',
            api_url: process.env.VUE_APP_STRAPI_URL,
        };
    }, 
    created() {
        document.querySelector('#idGA_lead').value = this.getUUID();
        setTimeout(() => {
            this.getUTMzz();
        }, 1000);
       
    }   
}
</script>
<style lang="styl" scoped>
    .toast-amil {
        background:$darkBlue;
    }
    .contact-form {
        background: #F6F6F6;
        padding:10px 0;
        .center-content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 80px;
            @media $large-mobile {
                grid-template-columns: 1fr;
            }
            figure {
                display: flex;
                align-items: center;
            }
            .contact-for__text {
                grid-column: 1 / -1;
                p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 21px;
                    color: #595758;  
                }              
            }
        }             
    }
    .contact-form__form {
        display:flex;
        flex-wrap:wrap;
        justify-content:space-between;
        align-items:flex-start;
        align-content:flex-start;
        .form-title {
            font-weight: 500;
            font-size: 32px;
            line-height: 40px;
            color: #3B3B3A;        
        }
        .form-intro {
            font-weight: 400;
            font-size: 22px;
            line-height: 38px;
            color: #3B3B3A; 
            margin: 50px 0;         
        }      
    }
    .form-group {
        width:100%;
        input,
        textarea,
        select {
            width:100%;
            border: 2px solid #ABABAB;
            margin-bottom: 30px; 
        }
        input {
            height: 41px;
            border-radius: 100px;
        }
        textarea {
            height: 224px;
            border-radius: 24px;    
        }
    }
    .error {
        margin-bottom:20px;
        input, 
        textarea {
            border:1px solid red;
            border-radius:5px;
            padding:10px;
        }
    }
    .half-field {
        width:48%;
    }
    .btn-form {
        padding: 15.5px 24px;
        gap: 10px;
        width: 174px;
        height: 48px;
        background: #D5086E;
        border-radius: 200px;
        color: #FFF;
        border: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;       

        &:hover {
        opacity: 0.9;
        color: #fff;
        }
        @media $large-mobile {
        margin-top: 20px
        }
}
</style>