import {mapActions} from 'vuex';
import axios from 'axios';
function getTitle (vm) {
    const { title } = vm.$options
    
    if (title) {
      
      return typeof title === 'function'
        ? title.call(vm)
        : title
    }
  }
  export default {    
    methods: {
        ...mapActions([
            'setCurrentComponents',
        ]),
        forceRender() {          
          const active_level = this.$store.state.level;
          axios.get(this.api_url+`/pages/getBySlug/${this.slug}?filters[level]=${active_level}&populate=*`).then((res) => {           
            const components = res.data.data.attributes.components;                 
            this.pageTitle = res.data.data.attributes.title;
            const title = getTitle(this);      

            if (title) {
              document.title = title + ' | ' + process.env.VUE_APP_CURRENT_SITE
            }      
            this.components = components;            
            const componentList = [];                            
            components.forEach(component => {                        
                componentList.push({
                        __typename: 'Component'+component.__component.replace(/-.|\../g, x=>x[1].toUpperCase()).replace(/^./g, x=>x[0].toUpperCase()),
                        ... component
                });                        
            });            
            this.$refs.ComponentList.componentListUpdate(componentList); 
          });                     
        }        
    },        
    created () {
      const title = getTitle(this);        
      if (title) {
        document.title = title + ' | ' + process.env.VUE_APP_CURRENT_SITE
      }      
    }       
  }