<template>
    <a :href="component.button_link" tabindex="0" class="link-more">
        {{ component.button_text }}
    </a>        
</template>
<style lang="styl"> 
.link-more {
  color: #D5086E !important;
  margin-top: 50px;
  font-weight: 500;
  padding-bottom: 32px;
  display:block;
  align-self: flex-start;
  font-size: 22px;
  @media $large-mobile {
      font-size: 18px;
      line-height:16.666px;
      margin-top:15px;
      align-self: center;
      text-align: center;
  }
  @media (max-width:1120px) {
      
      margin-top:0;
  }
  &:after {
      content:'';
      display:block;
      max-width: 100%;
      width: 300px;
      height:2px;
      background: #D5086E;
      @media $large-mobile {
          margin: 0 auto;
          width: 249px;
      }
  }
}
</style>
<script>
export default {
    name: "ComponentLink",
    props: {
        component: Object,
    },
}
</script>