<template>
  <div>

    <div v-if="component" id="citation">
      <section class="statement" :class="{ bg_lightGrey: component.gray_background }">
        <div class="center-content">
          <picture>
            <source :srcset="api_url + component.img_mobile.data.attributes.url" media="(max-width:991px)">
            <img :src="api_url + component.image.data.attributes.url" alt="component.image.data.attributes.alternativeText" class="statement-picture" />
          </picture>
          <div class="text-content">
            <div class="t-35 t-lh-40 t-darkBlue" v-html="component.depoiment">

            </div>
            <h3 class="t-18 t-lh-25 t-blue t-sb">
              {{ component.person_name }}
            </h3>
            <h4 class="t-18 t-lh-25">{{ component.role }}</h4>
          </div>
        </div>

      </section>
    </div>
  </div>
</template>
<style lang="styl">
#citation {
  margin-top: 125px;
  margin-bottom: 125px;
}
.statement{
        padding: 60px 0px 60px;

      .center-content{
          align-items: center;
          display: flex;
          position: relative;

          h3{
              color: $blue;
          }

          p{
              color: $darkBlue;
          }

          .statement-picture{
              border-radius: 15px;
              left: -30px;
              position: absolute;
              overflow: hidden;
              top: -118px;

              @media $large-mobile{
                  margin: -105px auto 60px;
                  max-width: 300px;
                  position: initial;
              }
          }

          .text-content{
              margin-left: auto;
              max-width: 545px;
              position: relative;

              p{
                  margin-bottom: 15px;                  
                  @media $mobile-max{
                      text-align: left;
                  }
              }

              p:before{
                  background: url(../../images/asp-left.png);
                  background-repeat: no-repeat;
                  content:""
                  display: inline-block;
                  height: 50px;
                  left: -45px;
                  position: absolute;
                  top: -25px;
                  width 50px;

                  @media $mobile-max{
                      height: 39px;
                      left: -20px;
                      top: -45px;
                      width 39px;
                  }
              }

              p:after{
                  background: url(../../images/asp-right.png);
                  background-repeat: no-repeat;
                  content:"";
                  display: inline-block;
                  height: 50px;
                  position: absolute;
                  bottom: 55px;
                  width: 50px;

                  @media $large-mobile{
                      right: 30px;
                  }

                    @media $mobile-max{
                      bottom: 70px;
                      height: 39px;
                      right: 55px;
                      width: 39px;
                  }

                  @media (max-width: $minSmallerContextPX ){
                      right: 60px;
                  }
              }

              @media $large-mobile{
                  margin: auto;
              }
          }

        @media $large-mobile{
              display: block;
              text-align: center;
              padding: 0px 35px !important;
        }
      }

      @media $large-mobile{
        margin-top: 90px;
      }
}
</style>
<script>
export default {
  name: "ComponentSectionsCitation",
  props: {
    component: Object,
  },
  data() {
    return {
      api_url: process.env.VUE_APP_STRAPI_URL,
      banners: {},
    };
  },
};
</script>
