<template>
    <div class="component-popup" >
        <div v-if="component">         
            <div class="popup"> 
                <div class="modal_popup">
                    <div class="close_popup" @click="closeModal">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 330 330" xml:space="preserve"  >
                        <g id="XMLID_28_">
                            <path id="XMLID_29_" d="M165,0C120.9,0,79.5,17.2,48.3,48.3c-64.3,64.3-64.3,169,0,233.3C79.5,312.8,120.9,330,165,330
                                c44.1,0,85.5-17.2,116.7-48.3c64.3-64.3,64.3-169,0-233.3C250.5,17.2,209.1,0,165,0z M239.2,239.2c-2.9,2.9-6.8,4.4-10.6,4.4
                                c-3.8,0-7.7-1.5-10.6-4.4l-53-53l-53,53c-2.9,2.9-6.8,4.4-10.6,4.4c-3.8,0-7.7-1.5-10.6-4.4c-5.9-5.9-5.9-15.4,0-21.2l53-53l-53-53
                                c-5.9-5.9-5.9-15.4,0-21.2c5.9-5.9,15.4-5.9,21.2,0l53,53l53-53c5.9-5.9,15.4-5.9,21.2,0c5.9,5.9,5.9,15.4,0,21.2l-53,53l53,53
                                C245.1,223.9,245.1,233.4,239.2,239.2z"/>
                        </g>
                        </svg>
                    </div>
                    <div class="text-content">
                        <h2 class="t-25 t-lh-25 t-black" v-html="component.title"></h2> 
                        <p class="t-20 t-lh-25" v-html="component.text"></p>                           
                    </div>                 
                    
                </div>
            </div>
        </div>
    </div>    
</template>
<style lang="styl">
.component-popup {
    .popup {
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100vh;
        position: fixed;
        background: rgba(80,80,80,.9);
        display: flex;
        align-items: center;
        left: 0;
        z-index: 9999;
        justify-content: center;
        .modal_popup {
            padding: 40px 35px;
            width: 768px;
            background-color: #fff;
            border: 3px solid #d5086e;
            position: relative;
            border-radius: 20px;
            @media $large-mobile {
                width: 90vw;
                padding: 25px 20px;
            }
            .text-content {
                h2 {
                    color:#d5086e;
                }
            }
            .close_popup {
                display: flex;
                align-items: center;
                border-radius: 50%;
                background-color: #fff;
                cursor: pointer;
                font-size: 9px;
                font-weight: 800;
                height: 31px;
                justify-content: center;
                position: absolute;
                right: -15px;
                top: -15px;
                width: 31px;
                svg {
                    fill: #d5086e;
                    &:hover {
                        fill: #f60d81;
                    }                   
                } 
            }
        }
    }
}
</style>
<script>
export default {   
    name: "ComponentSections",    
    props: {
        component: Object,
    },
    data() {        
        return {            
            api_url: process.env.VUE_APP_STRAPI_URL,
            banners: {},
        };
    },
    methods: {
        closeModal() {
            document.querySelector('.popup').style.display = 'none'            
        }
   },
   
}
</script>


