<template>
    <section  v-if="component" aria-label="componente vídeo"  :style="{ background: component.bg_color ? component.bg_color : '#f6f6f6' }">                   
        <div class="container" >
            <div class="Video" :class="{ active_text: component.active_text, reverse: component.reverse }" ref="video">  
                <h2  v-if="component.title || !component.active_text" class="video_title" aria-label="Video Amil One" :style="{color: component.text_color == '#fff' ? '#333333' : (component.text_color ? component.text_color : '#3b3b3a'), marginBottom : component.title ? '30px' : '0px' }" v-html="component.title"></h2>          
                <div class="video_component">
                    <Video :width="component.largura_video == '913' ? '511' : (component.largura_video ? component.largura_video : '913')" :height="component.altura_video == '530' ? '368' : (component.altura_video ? component.altura_video : '530')" :url="formattedUrl(component.url)" :title="component.title_video" ref="video" />
                    <Link v-if="component.button_text" class="link_mobile" :component="component" />
                </div>
                <div class="video__content" :style="{color: component.text_color == '#fff' ? '#333333' : (component.text_color ? component.text_color : '#3b3b3a') }">
                    <h2 class="video__content_title"  v-if="component.title || !component.active_text" aria-label="Video Amil One" :style="{ color: component.text_color == '#fff' ? '#333333' : (component.text_color ? component.text_color : '#3b3b3a'), marginBottom : component.title ? '30px' : '0px' }" v-html="component.title"></h2> 
                    <p class="video__text" v-if="component.text" v-html="component.text"></p>
                    <Link v-if="component.button_text" class="link_desktop" :component="component" />
                </div>
            </div>
        </div>
    </section>    
    
</template>

<style lang="styl" scoped>
.Video {
    padding: 80px 0;
    @media $large-mobile {
        padding: 80px 33px;
    }
    @media $mobile-max {
        padding: 30px 33px;
    }
    .video_title {
        text-align: center;
    }
    .video_title {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        @media $mobile-max {
            font-size: 22px;
            line-height: 32px                       
        }
    }
    .video__content {
        display: none;
        @media $large-mobile {
            text-align: center;
        }
        .video__text {
            height:100%;
            font-weight: 400;
            line-height: 38px;
            @media $large-mobile {
                max-width: 100%;
                font-weight: 400;
                font-size: 18px;
                line-height: 32px;
                padding: 0;
            }            

        }
    }
    .link_desktop {
        @media $large-mobile {
            display: none;
        }
    }
    .link_mobile {
        display: none;
        @media $large-mobile {
            display: block;
            margin-top: 50px;
        }
    }
    &.active_text {        
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        @media $large-mobile {
            grid-template-columns: 1fr;
        }
        .video_title {
            display: none;
        }
        .video__content_title {
            font: normal normal 100 75px/88px Amil Typeface;
            @media $large-mobile {
                font: normal normal 100 55px/59px Amil Typeface;
            }
        }
        .video__text {
            font: normal normal 100 22px/27px Amil Typeface;
            letter-spacing: 0px;
            @media $large-mobile {
                font: normal normal 100 18px/27px Amil Typeface;
            }
        }
        .video__content {
            display: block !important;
            @media $large-mobile {
                padding: 0;
            }
        }
        iframe {
            height: 100% !important;
        }
    }
    &.reverse {
        .video_component {
            order: 2 !important;
            max-width: 100%;
            @media $large-mobile {
                max-width: 100%;
            }
        }
        .video__content {
            order: 1 !important;
            text-align: left;
        }
    }
}

</style>
<script>
import Video from '../Video.vue';
import Link from '../Link.vue';
export default {
    name: "ComponentVideo",
    props: {
        component: Object,
    }, 
    components: {
        Video,
        Link
    },
    data() {        
        return {
            api_url: process.env.VUE_APP_STRAPI_URL,
            bg_color: this.component.bg_color ? this.component.bg_color : '#f6f6f6',
            text_color: this.component.text_color ? this.component.text_color : '#3b3b3a',

        };
             
    },
    mounted() {       
        this.$refs.video.style= "background: " + this.bg_color + "; color: " + this.text_color + ";"
    },
    methods: {
        formattedUrl(url) {
            return url.replace('watch?v=', 'embed/')
        }
        
    }
}
</script>
