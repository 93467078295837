<template>
  <section class="information" aria-label="Últimas Noticias Amil One">
    <div class="center-content">
      <div class="title-section">
        <h2 v-if="component.title">
          {{ component.title }}
        </h2>
      </div>
      <div class="description-news" v-html="component.description"></div>
      <div class="ultimas-noticias" v-if="noticias_filter">
        <div
          v-for="items in noticias_filter"
          class="ultimas-noticias__list_item"
          :key="items.id"
        >
          <div v-for="noticia in items" :key="noticia.id">
            <a v-if="noticia.slug" :href="'/noticias/' + noticia.slug">
              <figure v-for="img in noticia.featured_image" :key="img.id">
                <img
                  v-if="img.attributes.url"
                  :src="api_url + img.attributes.url"
                  alt="últimas Noticia Imagem"
                  class="ultimas-noticias__list_item_image_wrap"
                />
              </figure>
              <div class="ultimas-noticias__list_item_content" v-if="noticia.categories">
                <div
                  class="ultimas-noticias__list_item_category"
                  v-for="categoria in noticia.categories"
                  :key="categoria.id"
                  v-if="noticia.categories"
                >
                  {{ categoria[0].attributes.name }}
                </div>
                <h3 v-if="noticia.title" class="ultimas-noticias__list_item_title">
                  {{ noticia.title }}
                </h3>
                <div
                  class="ultimas-noticias__list_item_description"
                  v-html="noticia.excerpt"
                ></div>
                <a v-if="noticia.slug" :href="'/noticias/' + noticia.slug" class="link-read-more" aria-label="Continar Lendo Noticia">Continuar lendo</a>
              </div>
            </a>
          </div>
        </div>
        <a
          class="ultimas-noticias__button"          
          :href="component.button_link"
          v-if="component.button"
          aria-label="Ver mais noticias no Leia Saúde"
          >{{ component.button }}</a
        >
      </div>
    </div>
  </section>
</template>
<style lang="styl" scoped>
.information {
  background:  #F6F6F6;
}
.title-section {
  padding-top: 90px;
  h2 {
    color: #3B3B3B;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    @media $mobile-max {
      font-size: 28px;
      line-height: 29.15px;
    }
  }
}
.ultimas-noticias {
  display: grid;
  grid-template-columns:  repeat(3, 1fr);
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  margin-top: 40px;
  padding-bottom: 30px;
  @media $large-mobile {
    grid-template-columns: 1fr;
  }
  
  .ultimas-noticias__button {
    grid-column: 1 / -1;
      padding: 15.5px 24px;
      gap: 10px;
      background: #D5086E;
      border-radius: 200px;
      color: #FFF;
      font-weight: 700;
      font-size: 16px;
      line-height: 17px;
      text-align: center;
      width: 301px;
      height: 48px;
      margin: 64px auto;
      &:hover {
        background: #f60d81;
      }
  }
  .ultimas-noticias__list_item {
    display: flex;
    flex-direction: column;
    max-width: 308px;
    position:relative;
    @media $large-mobile {
      flex-direction: column;
      margin-top: 50px;
    }
    .ultimas-noticias__list_item_content .link-read-more {
      color: #D5086E;
      display:block;
      margin:0 auto;
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: #D5086E;      
      }
    }
    img {
      border-radius:16px 16px 0 0 ;
    }
    figure {       
        width:100%;
        height:129px;
        .ultimas-noticias__list_item_image_wrap {
          width:100%;
          height:100%;
          object-fit: cover;
        }
      }
    .ultimas-noticias__list_item_content {
      background: #FFFFFF;
      height: auto;
      padding-bottom: 50px;
      padding: 20px;
      border-radius: 0 0 16px 16px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
      display: flex;
      flex-direction: column;
      align-items: center;
      

      .ultimas-noticias__list_item_category {
        padding: 4px 12px;
        background: #3B3B3A;
        border-radius: 4px;
        color: #FFF;
        display: inline;
        font-size: 12px;
        margin-top: -30px;
      }
      .ultimas-noticias__list_item_title {
        font-weight: 500;
        font-size: 18px;
        line-height: 1.2;
        margin-top: 20px;
      }
      .ultimas-noticias__list_item_description {
        font-size: 16px;
        line-height: 24px;
        color: #595758;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: 300;
        max-height: 93px;
        overflow:hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;

      }
      a {
        color: #D5086E;
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;
        text-decoration: underline;
      }
    }
    .ultimas-noticias__list_item_footer {
      padding: 17px 0;
      margin-top: 25px;
      border-top: 1px solid #D9D9D9;
      display: flex;
      align-items: center;
      gap: 80px;
      color: #595758;
      figure {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
}
</style>
<script>
import Button from "../Button.vue"
import axios from "axios";
import _ from "lodash";
export default {
  name: "ComponentSectionsSlider",
  props: {
    component: Object,
    
  },
  methods: {
    strapi_api_url: function () {
      return process.env.VUE_APP_STRAPI_API_URL;
    },
    changeColor() {
      const category = document.querySelectorAll(".ultimas-noticias__list_item_category");
      const categoryArray = Array.from(category);
      categoryArray.filter((item, index) => {
        if (index % 2 == 0) item.style.background = "#D5086E";
      });
    },
    getNoticias: async function () {
      const api_url = this.strapi_api_url();
      let filters = `/noticias/?sort[0]=rank&pagination[limit]=3`;
      const categories = this.component.noticias.data.map((category) => {
        filters += ``;
      });
      let noticias = "";
      if (categories) {
        await axios.get(`${api_url}${filters}`).then((response) => {
          noticias = response.data.data;
        });
      }
      const res = {
        noticias,
      };
      this.teste = "3";
      this.noticias = noticias;
      return res;
    },
    async setup() {
      const noticias = await this.getNoticias();
      const styles = `
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          `;
      return {
        styles,
        noticias: noticias.noticias,
      };
    },
  },
  async mounted() {
    const data = await this.setup();
    this.noticias = data.noticias;
    this.noticias_filter = this.noticias;

    this.changeColor();
  },
  data() {
    return {
      teste: "1",
      noticias: {},
      arrayNoticias: {},
      noticias_filter: {},
      styles: {},
      api_url: process.env.VUE_APP_STRAPI_URL,
      banners: {},
    };
  },
};
</script>
